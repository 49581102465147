import React, { useContext, useEffect, useState } from "react";
import { FiEdit, FiPlus, FiTrash } from "react-icons/fi";
import {
  Button,
  Center,
  FileInput,
  Heading,
  InputBox,
  Loader,
  MyModal,
  MyPopup,
  PriorityDrop,
} from "../../utils/layoutUtils";
import Table from "../../components/Table";
import myContext from "../../data/myContext";
import { deleteApi, getApi, postApi, postApi1 } from "../../utils/apis";
import { toast } from "react-toastify";
import Select, { StylesConfig } from "react-select";
import { Image } from "antd";

const Notification = () => {
  const [open1, setOpen1] = useState(false);
  const [detail, setdetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const { getNotificationList, notification_list, users } =
    useContext(myContext);
  console.log(notification_list);
  const onAdd = async () => {
    if (!detail?.TITLE || !detail?.MESSAGE || !detail?.USERIDS?.length > 0) {
      toast.info("All fields are required", { toastId: 1 });
      return;
    }
    const body = {
      TITLE: detail.TITLE,
      DESCRIPTION: "ABCDEFGHIJKLMNOP",
      MESSAGE: detail.MESSAGE,
      USER_IDS: detail?.USERIDS?.map((v) => v.value),
    };

    const formdata = new FormData();
    formdata.append("TITLE", detail.TITLE);
    formdata.append("MESSAGE", detail.MESSAGE);
    detail?.img?.name && formdata.append("IMAGE", detail.img);
    formdata.append(
      "USER_IDS[]",
      detail?.USERIDS?.map((v) => v.value)
    );
    setLoading(true);
    const res = await postApi("notification/send", formdata);
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      setOpen(false);
      getNotificationList();
    } else {
      toast.error(res.message);
    }
  };
  useEffect(() => {
    getNotificationList();
  }, []);

  const onDelete = async () => {
    setLoading(true);
    const res = await deleteApi(`blogs/delete-blog-id/${detail.BLOG_ID}`);
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      setOpen1(false);
      getNotificationList();
    } else {
      toast.error(res.message);
    }
  };
  const list = [];
  for (let i = 0; i < users?.list.length; i++) {
    const element = {
      label: users?.list[i]?.userInfo[0]?.FULL_NAME,
      value: users?.list[i].USER_ID,
    };
    list.push(element);
  }
  const all_user = [{ label: "All Users", value: "all" }];
  const all_user_list = all_user.concat(list);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #f00",
    }),
  };

  return (
    <div className="w-full block">
      <div className="flex justify-between items-center">
        <Heading title={"Notification List"} />
        <Button
          title={"Add"}
          icon={<FiPlus size={20} />}
          onClick={() => {
            setdetail({});
            setEdit(false);
            setOpen(true);
          }}
        />
      </div>
      {notification_list?.loading && notification_list?.list?.length != 0 ? (
        <Loader />
      ) : (
        <Table
          searchValue={search}
          onSearchChange={(v) => {
            setSearch(v.target.value);
          }}
          noData={notification_list?.list}
          headData={["S.No.", "Title", "Message", "Image", "Date & Time"]}
        >
          {notification_list?.list
            // ?.filter((item) => {
            //   return item.C_NAME.toLowerCase().includes(search.toLowerCase());
            // })
            ?.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 text-center">{index + 1}</td>

                <td className="py-2 px-4 text-center">{item.TITLE}</td>

                <td className="py-2 px-4 text-center">
                  <div className="line-clamp-4">{item.MESSAGE}</div>
                </td>
                <td className="py-4 px-4 text-center">
                  <div className="items-center flex justify-center">
                    <Image width={100} src={item.FILE_PATH} />
                    {/* <img
                      src={item.FILE_PATH}
                      className="h-[50px] w-[50px] object-contain"
                    /> */}
                  </div>
                </td>
                <td className="py-2 px-4 text-center">{item.SEND_AT}</td>
              </tr>
            ))}
        </Table>
      )}
      <MyModal
        isOpen={open}
        setIsOpen={setOpen}
        title={edit ? "Edit Notification" : "Add Notification"}
      >
        <div className="p-5 w-full">
          <div className="flex w-full gap-4">
            <InputBox
              label={"Title"}
              placeholder={"Title"}
              value={detail.TITLE}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  TITLE: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                  TITLEMess: "",
                });
              }}
              error={detail.TITLEMess}
            />
            <InputBox
              area={true}
              label={"Message"}
              placeholder={"Message"}
              value={detail.MESSAGE}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  MESSAGE: v.target.value.trimStart(),
                  MESSAGEMess: "",
                });
              }}
              error={detail.MESSAGEMess}
            />
          </div>

          <div className="flex w-full gap-4">
            <div style={{ flex: 1 }} className="mt-4">
              <label marginBottom={2} marginLeft={2}>
                Select Users*
              </label>
              <Select
                placeholder={"Search and Select Users"}
                options={
                  detail?.USERIDS?.find((v) => v.value == "all")
                    ? all_user
                    : all_user_list
                }
                isMulti={true}
                value={detail.USERIDS}
                isSearchable
                // filterOption={customFilter}
                onChange={(v) => {
                  setdetail({
                    ...detail,
                    USERIDS: v,
                    USERIDSmess: "",
                    USERIDSval: false,
                  });
                }}
                styles={detail.USERIDSval && customStyles}
              />
            </div>
            <FileInput
              label={"Image (Optional)"}
              fileName={detail?.img?.name}
              onChange={(e) => {
                setdetail({ ...detail, img: e.target.files[0] });
              }}
            />
          </div>

          <Center className={"mt-8"}>
            <Button
              title={edit ? "Update" : "Submit"}
              onClick={onAdd}
              loading={loading}
            />
          </Center>
        </div>
      </MyModal>
      <MyPopup
        isOpen={open1}
        setIsOpen={setOpen1}
        title={"Delete"}
        message={"Are you sure? You want to delete data"}
        bname={"Delete"}
        onClick={() => onDelete()}
        loading={loading}
      ></MyPopup>
    </div>
  );
};

export default Notification;
