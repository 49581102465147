const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
const user = JSON.parse(localStorage.getItem("mycall_book"));
console.log("user", user?.TOKEN);

const onLogin = async (body, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(process.env.REACT_APP_API_KEY + "login", {
      method: "POST",
      headers: myHeaders,
      body: body,
    });
    const res = await responce.json();
    console.log(res);
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
    return { success: false, message: "Network request failed" };
  }
};

const postApi = async (endPoint, body) => {
  try {
    const responce = await fetch(process.env.REACT_APP_API_KEY + endPoint, {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.TOKEN}`,
      },
      body: body,
    });
    const res = await responce.json();
    console.log(res);
    return res;
  } catch (error) {
    return { success: false, message: "Network request failed" };
  }
};
const postApi1 = async (endPoint, body) => {
  try {
    const responce = await fetch(process.env.REACT_APP_API_KEY + endPoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.TOKEN}`,
      },
      body: body,
    });
    const res = await responce.json();
    console.log(res);
    return res;
  } catch (error) {
    return { success: false, message: "Network request failed" };
  }
};

const deleteApi = async (endPoint) => {
  try {
    const responce = await fetch(process.env.REACT_APP_API_KEY + endPoint, {
      method: "DELETE",
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.TOKEN}`,
      },
      // body: body,
    });
    const res = await responce.json();
    console.log(res);
    return res;
  } catch (error) {
    return { success: false, message: "Network request failed" };
  }
};

const putApi = async (endPoint, body) => {
  try {
    const responce = await fetch(process.env.REACT_APP_API_KEY + endPoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.TOKEN}`,
      },
      body: body,
    });
    const res = await responce.json();
    console.log(res);
    return res;
  } catch (error) {
    return { success: false, message: "Network request failed" };
  }
};
const getApi = async (endPoint) => {
  try {
    const responce = await fetch(process.env.REACT_APP_API_KEY + endPoint, {
      method: "GET",
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.TOKEN}`,
      },
      // body: body,
    });
    const res = await responce.json();
    console.log(res);
    return res;
  } catch (error) {
    return { success: false, message: "Network request failed" };
  }
};

export { onLogin, postApi, deleteApi, getApi, postApi1, putApi };
