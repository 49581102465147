import React, { useState } from "react";
import { Button, Card, InputBox } from "../utils/layoutUtils";
import { onLogin } from "../utils/apis";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: "",
    pass: "",
    // email: "0987654321",
    // pass: "Admin@0101",
  });
  const [loading, setLoading] = useState(false);
  const onLoginPress = async () => {
    if (!data.email || !data.pass) {
      toast.info("Please enter User ID and Password");
      return;
    }
    const body = JSON.stringify({
      PHONE_NUMBER: data.email,
      PASSWORD: data.pass,
    });
    const res = await onLogin(body, setLoading);
    console.log(res);
    if (res.success) {
      if (res.ROLE == "super-admin") {
        toast.success(res.message);
        localStorage.setItem("mycall_book", JSON.stringify(res));
        // window.location.reload();
        navigate("/");
      } else alert("You are not Admin");
    } else {
      toast.error(res.message);
    }
  };
  return (
    <div className="h-screen w-screen items-center justify-center flex">
      <div
        className={`bg-[#fff] rounded-md border-[2px] border-[#EBECF3] p-4 min-w-[500px] gap-4 flex flex-col`}
      >
        <div className="font-medium text-[#000] text-[18px] text-center ">
          Login
        </div>
        <InputBox
          placeholder={"User ID"}
          value={data?.email}
          onChange={(v) =>
            setData({ ...data, email: v.target.value, emailMess: "" })
          }
          error={data?.emailMess}
        />
        <InputBox
          type={"password"}
          placeholder={"Password"}
          value={data?.pass}
          onChange={(v) =>
            setData({ ...data, pass: v.target.value, passMess: "" })
          }
          error={data?.passMess}
        />
        <div className="flex items-center justify-center">
          <Button
            loading={loading}
            title={"Login"}
            onClick={() => onLoginPress()}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
