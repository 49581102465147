import React, { useContext, useEffect, useState } from "react";
import { FiEdit, FiPlus, FiTrash } from "react-icons/fi";
import {
  Button,
  Center,
  FileInput,
  Heading,
  InputBox,
  Loader,
  MyModal,
  MyPopup,
  PriorityDrop,
  Toggle,
} from "../../utils/layoutUtils";
import Table from "../../components/Table";
import myContext from "../../data/myContext";
import { deleteApi, getApi, postApi, postApi1 } from "../../utils/apis";
import { toast } from "react-toastify";

const Blogs = () => {
  const [open1, setOpen1] = useState(false);
  const [detail, setdetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [appBlog, setAppBlog] = useState(false);
  const [webBlog, setWebBlog] = useState(false);
  const { getBlogsList, blogs_list, blog_status, getCheckBlogStatus } =
    useContext(myContext);

  const onAdd = async () => {
    if (!detail?.TITLE || !detail?.DESCRIPTION || !detail?.IMAGE_URL?.name) {
      toast.info("All fields are required", { toastId: 1 });
      return;
    }
    const body = new FormData();
    body.append("TITLE", detail.TITLE);
    body.append("DESCRIPTION", detail.DESCRIPTION);
    body.append("IMAGE", detail.IMAGE_URL);
    body.append("VIDEO_URL", !detail.VIDEO_URL ? "" : detail.VIDEO_URL);

    setLoading(true);
    const res = await postApi(edit ? "blogs/update" : "blogs/create", body);
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      setOpen(false);
      getBlogsList();
    } else {
      toast.error(res.message);
    }
  };

  const change_status = async (status, id) => {
    setLoading1(true);
    const raw = JSON.stringify({
      IS_APP: id == 1 ? true : false,
      ACTIVE: status,
    });
    const res = await postApi1("blog-status/change-status", raw);
    setLoading1(false);
    if (res.success) {
      getCheckBlogStatus();
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  useEffect(() => {
    getBlogsList();
    setAppBlog(blog_status?.data?.ACTIVE_FOR_APP);
    setWebBlog(blog_status?.data?.ACTIVE_FOR_WEB);
  }, []);

  const onDelete = async () => {
    setLoading(true);
    const res = await deleteApi(`blogs/delete-blog-id/${detail.BLOG_ID}`);
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      setOpen1(false);
      getBlogsList();
    } else {
      toast.error(res.message);
    }
  };

  return (
    <div className="w-full block">
      <div className="flex justify-between items-center">
        <Heading title={"Blogs List"} />
        <Button
          title={"Add"}
          icon={<FiPlus size={20} />}
          onClick={() => {
            setdetail({});
            setEdit(false);
            setOpen(true);
          }}
        />
      </div>
      {blogs_list?.loading && blogs_list?.list?.length != 0 ? (
        <Loader />
      ) : (
        <Table
          topleft={
            <div>
              <p>Blog Status</p>
              <div className="flex gap-5 my-2">
                <Toggle
                  checked={appBlog}
                  onChange={(e) => {
                    setAppBlog(e.target.checked);
                    change_status(e.target.checked, 1);
                  }}
                  title={"For App"}
                />
                <Toggle
                  checked={webBlog}
                  onChange={(e) => {
                    setWebBlog(e.target.checked);
                    change_status(e.target.checked, 2);
                  }}
                  title={"For Website"}
                />
              </div>
            </div>
          }
          searchValue={search}
          onSearchChange={(v) => {
            setSearch(v.target.value);
          }}
          noData={blogs_list?.list}
          headData={[
            "S.No.",
            "TITLE",
            "Description",
            "IMAGE",
            "VIDEO_URL",
            "Actions",
          ]}
        >
          {blogs_list?.list
            // ?.filter((item) => {
            //   return item.C_NAME.toLowerCase().includes(search.toLowerCase());
            // })
            ?.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 text-center">{index + 1}</td>

                <td className="py-2 px-4 text-center">{item.TITLE}</td>

                <td className="py-2 px-4 text-center">
                  <div className="line-clamp-4">{item.DESCRIPTION}</div>
                </td>

                <td className="py-4 px-4 text-center">
                  <div className="items-center flex justify-center">
                    <img
                      src={process.env.REACT_APP_IMG_URL + item.IMAGE_URL}
                      className="h-[50px] w-[50px] object-contain"
                    />
                  </div>
                </td>
                <td className="py-2 px-4 text-center">{item.VIDEO_URL}</td>

                <td className="p-2 whitespace-nowrap ">
                  <div className="flex gap-2 justify-center">
                    {/* <FiEdit
                      size={20}
                      className="cursor-pointer"
                      onClick={() => {
                        setdetail(item);
                        setEdit(true);
                        setOpen(true);
                      }}
                    /> */}
                    <FiTrash
                      size={20}
                      className="cursor-pointer"
                      onClick={() => {
                        setdetail(item);
                        setOpen1(true);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </Table>
      )}
      <MyModal
        isOpen={open}
        setIsOpen={setOpen}
        title={edit ? "Edit Blog" : "Add Blog"}
      >
        <div className="p-5 w-full">
          <div className="flex w-full gap-4">
            <InputBox
              label={"Title"}
              placeholder={"Title"}
              value={detail.TITLE}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  TITLE: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                  TITLEMess: "",
                });
              }}
              error={detail.TITLEMess}
            />
            <InputBox
              area={true}
              label={"Description"}
              placeholder={"Description"}
              value={detail.DESCRIPTION}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  DESCRIPTION: v.target.value
                    .trimStart()
                    .replace(/\s{2,}/g, " "),
                  DESCRIPTIONMess: "",
                });
              }}
              error={detail.DESCRIPTIONMess}
            />
          </div>

          <div className="flex w-full gap-4">
            <FileInput
              label={"Image"}
              placeholder={"Image"}
              fileName={detail.IMAGE_URL?.name}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  IMAGE_URL: v.target.files[0],
                  IMAGE_URLMess: "",
                });
              }}
              error={detail.IMAGE_URLMess}
            />
            <InputBox
              label={"Video Url (Optinal)"}
              placeholder={"Video Url (Optinal)"}
              value={detail.VIDEO_URL}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  VIDEO_URL: v.target.value.trim(),
                  VIDEO_URLMess: "",
                });
              }}
              error={detail.VIDEO_URLMess}
            />
          </div>

          <Center className={"mt-8"}>
            <Button
              title={edit ? "Update" : "Submit"}
              onClick={onAdd}
              loading={loading}
            />
          </Center>
        </div>
      </MyModal>
      <MyPopup
        isOpen={open1}
        setIsOpen={setOpen1}
        title={"Delete"}
        message={"Are you sure? You want to delete data"}
        bname={"Delete"}
        onClick={() => onDelete()}
        loading={loading}
      ></MyPopup>
    </div>
  );
};

export default Blogs;
