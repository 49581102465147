import React, { useState } from "react";
import logo from "../assets/logo.png";
import { MyPopup } from "../utils/layoutUtils";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
const Header = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center gap-3">
        <img
          src={logo}
          style={{ height: 50, width: 50, objectFit: "contain" }}
        />
        <p className="font-sans font-semibold text-[20px]">MyCall Book</p>
      </div>
      <div className="cursor-pointer" onClick={() => setOpen(true)}>
        Logout
      </div>
      {/* <MyPopup
        isOpen={open}
        setIsOpen={setOpen}
        title={"Logout"}
        message={"Are you sure? You want to logout"}
        bname={"Logout"}
        onClick={() => {
          localStorage.removeItem("mycall_book");
          navigate("/login");
        }}
      ></MyPopup> */}
      <Modal
        title=""
        centered
        width={400}
        open={open}
        // onOk={handleOk}
        onCancel={() => setOpen(false)}
        footer={<></>}
      >
        <h3 className="text-lg font-medium text-gray-900">Logout</h3>
        <div className="px-6 py-4">
          <p className="text-md text-gray-700">
            Are you sure? You want to logout
          </p>
        </div>
        <div className=" pt-4 flex justify-end">
          <button
            className="text-sm text-gray-500 hover:text-gray-700 mr-3 bg-slate-400 py-1 px-3 rounded-md"
            onClick={() => setOpen(false)}
          >
            Cancel
          </button>
          <button
            className={`text-sm justify-center  flex text-white min-w-16  py-1 px-3 rounded-md bg-red-700 hover:bg-red-800`}
            onClick={() => {
              localStorage.removeItem("mycall_book");
              navigate("/login");
            }}
          >
            Logout
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Header;
