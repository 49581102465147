import React, { useContext, useEffect, useState } from "react";
import { Heading, Loader, MyModal, ViewData } from "../../utils/layoutUtils";
import Table from "../../components/Table";
import myContext from "../../data/myContext";
import { postApi1 } from "../../utils/apis";
import { toast } from "react-toastify";
import { Image, Tooltip } from "antd";

const Provider_data = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState({});
  const { getProviderList, provider } = useContext(myContext);

  useEffect(() => {
    getProviderList();
  }, []);

  const onApproved = async (id, status) => {
    setLoading(true);
    try {
      const body = JSON.stringify({
        STATUS: status,
        ID: id,
      });

      const res = await postApi1("service-info/verify", body);
      console.log(res);
      if (res.success) {
        getProviderList();
        toast.success(res.message);
        setOpen(false);
      } else {
        toast.error(res.message);
      }
    } catch (error) {}
  };

  return (
    <div className="w-full block">
      <div className="flex justify-between items-center">
        <Heading title={"New Provider Request List"} />
      </div>
      {provider.loading ? (
        <Loader />
      ) : (
        <Table
          searchValue={search}
          onSearchChange={(v) => {
            setSearch(v.target.value);
          }}
          noData={provider.list}
          headData={[
            "S.No.",
            "BUSINESS_NAME",
            "Email Address",
            "Business Number",
            "Business Address",
            "Description",
            "Id Proof",
            "Address Proof",
            "Status",
          ]}
        >
          {provider.list
            ?.filter((item) => {
              return (
                item.BUSINESS_NAME.toLowerCase().includes(
                  search.toLowerCase()
                ) ||
                item.BUSINESS_EMAIL.toLowerCase().includes(
                  search.toLowerCase()
                ) ||
                item.BUSINESS_NUMBER.toString().includes(search)
              );
            })
            ?.map((item, index) => (
              <tr className="text-center" key={index}>
                <td className="p-2 whitespace-nowrap">{index + 1}</td>

                <td
                  className="p-2 whitespace-nowrap w-[20%] cursor-pointer text-indigo-500 font-sans"
                  onClick={() => {
                    setDetail(item);
                    setOpen(true);
                  }}
                >
                  {item.BUSINESS_NAME}
                </td>
                <td className="p-2 whitespace-nowrap w-[20%] font-sans ">
                  {item.BUSINESS_EMAIL}
                </td>
                <td className="p-2 whitespace-nowrap w-[20%] font-sans">
                  {item.BUSINESS_NUMBER}
                </td>
                <td className="p-2 whitespace-normal">
                  <Tooltip title={item.BUSINESS_ADDRESS}>
                    <div className="w-[250px] line-clamp-4 font-sans">
                      {item.BUSINESS_ADDRESS}
                    </div>
                  </Tooltip>
                </td>
                <td className="p-2 whitespace-normal">
                  <Tooltip title={item.DESCRIPTION}>
                    <div className="w-[250px] line-clamp-4 font-sans">
                      {item.DESCRIPTION}
                    </div>
                  </Tooltip>
                </td>
                <td className="p-2 whitespace-nowrap">
                  <div className="flex justify-center">
                    <Image
                      height={70}
                      src={process.env.REACT_APP_IMG_URL + item.ID_CARD}
                      // className="h-[100px] w-[100px] object-contain"
                    />
                  </div>
                </td>
                <td className="p-2 whitespace-nowrap">
                  <div className="flex justify-center">
                    <Image
                      // width={50}
                      height={70}
                      src={process.env.REACT_APP_IMG_URL + item.ADDR_PROOF}
                      // className="h-[100px] w-[100px] object-contain"
                    />
                  </div>
                </td>
                <td className="p-2 whitespace-nowrap ">
                  <div className="flex gap-2 justify-center">
                    <select
                      className="w-[120px] border-[1px] border-[#655ADF] py-1 px-2 rounded-[5px]"
                      value={item?.IS_VERIFY}
                      onChange={(v) => onApproved(item.ID, v.target.value)}
                    >
                      <option value={0}>Pending</option>
                      <option value={1}>Approved</option>
                      <option value={2}>Rejected</option>
                    </select>
                  </div>
                </td>
              </tr>
            ))}
        </Table>
      )}

      <MyModal
        isOpen={open}
        setIsOpen={setOpen}
        title={"Service Provider Details"}
      >
        <div className="p-5">
          <p className="mb-2 font-sans font-medium text-lg text-[#44195E]">
            Personal Detail
          </p>
          <ViewData title={"Full Name"} value={detail?.PROFILE?.FULL_NAME} />
          <ViewData
            title={"Email Address"}
            value={detail?.PROFILE?.EMAIL_ADDRESS}
          />
          <ViewData
            title={"Contact Number"}
            value={detail?.PROFILE?.PHONE_NUMBER}
          />
          <p className="mb-2 font-sans font-medium text-lg text-[#44195E]">
            Business Detail
          </p>
          <ViewData title={"Business Name"} value={detail?.BUSINESS_NAME} />
          <ViewData
            title={"Business Email Address"}
            value={detail?.BUSINESS_EMAIL}
          />
          <ViewData title={"Contact Number"} value={detail?.BUSINESS_NUMBER} />
          <ViewData title={"DESCRIPTION"} value={detail?.DESCRIPTION} />
          <ViewData
            title={"Business Address"}
            value={detail?.BUSINESS_ADDRESS}
          />
          <ViewData title={"Experience"} value={detail?.EXPERIENCE} />
          {detail?.WEB_URL && (
            <ViewData title={"Website Url"} value={detail?.WEB_URL} />
          )}
          <ViewData title={"Pincode"} value={detail?.PINCODE} />

          {detail?.CATEGORY_INFO?.length > 0 && (
            <ViewData
              title={"Category"}
              value={detail?.CATEGORY_INFO[0]?.C_NAME}
            />
          )}
          {detail?.SERVICE_INFO?.length > 0 &&
            detail?.SERVICE_INFO[0]?.SUB_SERVICES_DATA?.length > 0 && (
              <ViewData
                title={"Sub Categories"}
                value={detail?.SERVICE_INFO[0]?.SUB_SERVICES_DATA?.map(
                  (v) => v.S_NAME
                )?.toString()}
              />
            )}
          <ViewData title={"ID Proof"} img={detail?.ID_CARD} />
          <ViewData title={"Address Proof"} img={detail?.ADDR_PROOF} />
          <ViewData title={"Work Gallery"} list={detail?.WORK_GALLERY} />
        </div>
      </MyModal>
    </div>
  );
};

export default Provider_data;
