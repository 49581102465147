import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Center,
  FileInput,
  Heading,
  InputBox,
  Loader,
  MyModal,
  MyPopup,
  PriorityDrop,
} from "../../utils/layoutUtils";
import Table from "../../components/Table";
import myContext from "../../data/myContext";
import { deleteApi, getApi, postApi, putApi } from "../../utils/apis";
import { toast } from "react-toastify";
import { FiPlus, FiTrash } from "react-icons/fi";
import imageCompression from "browser-image-compression";
import { Image } from "antd";

const SliderImg = () => {
  const user = JSON.parse(localStorage.getItem("mycall_book"));
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detail, setdetail] = useState({});
  const [open1, setOpen1] = useState(false);
  const [search, setSearch] = useState("");
  const { getSliderList, slider_img, category } = useContext(myContext);
  const [selected, setSelected] = useState("Home Top");
  const [option, setOption] = useState("Category");
  const [select_id, setSelect_id] = useState(null);
  const [cat_id, setCat_id] = useState("");
  const [sub_id, setSub_id] = useState("");
  const [sub2_id, setSub2_id] = useState("");
  const [sub_list, setSub_list] = useState([]);
  const [sub2_list, setSub2_list] = useState([]);
  const [provider_id, setProvider_id] = useState("");
  const [provider_list, setProvider_list] = useState([]);
  const [compressedFile, setCompressedFile] = useState({});
  const [originalFile, setOriginalFile] = useState({});
  const [originalSize, setOriginalSize] = useState(0);
  const [compressedSize, setCompressedSize] = useState(0);

  const page_name = [
    "Home Top",
    "Home Bottom",
    "Category Top",
    "Category Bottom",
    "Sub-Category Top",
    "Sub-Category Bottom",
    "Sub-Category2 Top",
    "Sub-Category2 Bottom",
  ];
  const link = ["Category", "Sub  Category", "Sub  Category2", "Provider"];

  useEffect(() => {
    getSliderList(selected);
  }, []);

  const onAdd = async () => {
    if (!selected || !detail?.img?.name) {
      toast.info("All fields are required", { toastId: 1 });
      return;
    }
    if (
      (select_id == 1 && !cat_id) ||
      (select_id == 2 && !sub_id) ||
      (select_id == 3 && !sub2_id) ||
      (select_id == 4 && !provider_id)
    ) {
      toast.info(
        (select_id == 1 && !cat_id && "Please Select Category") ||
          (select_id == 2 && !sub_id && "Please Select Sub Category") ||
          (select_id == 3 && !sub2_id && "Please Select Sub Category 2") ||
          (select_id == 4 && !provider_id && "Please Select Provider"),
        { toastId: 1 }
      );
      return;
    }

    const body = new FormData();

    body.append("IMAGE", compressedFile);
    body.append("PAGE_NAME", selected);
    body.append("CATEGORY_ID", cat_id);
    select_id == 2 && body.append("SUB_CATEGORY_ONE_ID", sub_id);
    select_id == 3 && body.append("SUB_CATEGORY_TWO_ID", sub2_id);
    select_id == 4 && body.append("SERVICE_PROVIDER_ID", provider_id);

    setLoading(true);
    const res = await postApi("slider-info/add", body);
    setLoading(false);
    if (res.success) {
      getSliderList(selected);
      toast.success(res.message);
      setOpen(false);
    } else {
      toast.error(res.message);
    }
  };

  const onDelete = async () => {
    setLoading(true);
    const res = await deleteApi(`slider-info/id/${detail.ID}`);
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      setOpen1(false);
      getSliderList(selected);
    } else {
      toast.error(res.message);
    }
  };
  const onUpdatePriority = async (id, priority) => {
    setLoading(true);
    const res = await getApi(
      `slider-info/update/id-priority/${id}/${priority}`
    );
    setLoading(false);
    if (res.success) {
      getSliderList(selected);
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    setOriginalFile(URL.createObjectURL(file));
    setOriginalSize(file.size);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      setCompressedFile(compressedFile);
      // setCompressedFile(URL.createObjectURL(compressedFile));
      setCompressedSize(compressedFile.size);
    } catch (error) {
      console.error("Error compressing image:", error);
    }
  };
  const get_sub_cat = async (id) => {
    try {
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + `services/list/cat_id/${id}`,
        { method: "GET" }
      );
      const res = await responce.json();
      if (res.success) {
        get_sub_cat_2(res.data[0]?.ID);
        setSub_id(res.data[0]?.ID);
        setSub_list(res.data);
      } else {
        setSub_id("");
        setSub_list([]);
      }
    } catch (error) {}
  };
  const get_sub_cat_2 = async (id) => {
    try {
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + `services1/list/ser_id/${id}`,
        { method: "GET" }
      );
      const res = await responce.json();
      if (res.success) {
        get_provider_list(res.data[0]?.ID);
        setSub2_id(res.data[0]?.ID);
        setSub2_list(res.data);
      } else {
        setSub2_id("");
        setSub2_list([]);
      }
    } catch (error) {}
  };
  const get_provider_list = async (id) => {
    try {
      const body = JSON.stringify({
        SUB_CATEGORY_ID: id,
      });
      const responce = await fetch(
        process.env.REACT_APP_API_KEY +
          `service-info/list-based-on-sub-subcategory-two`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: body,
        }
      );
      const res = await responce.json();
      console.log("id", res);
      if (res.success) {
        setProvider_id(res.data[0]?.USER_ID);
        setProvider_list(res.data);
      } else {
        setProvider_id("");
        setProvider_list([]);
      }
    } catch (error) {}
  };

  return (
    <div className="w-full block">
      <div className="flex justify-between items-center">
        <Heading title={"Users Query List"} />
        <Button
          title={"Add"}
          icon={<FiPlus size={20} />}
          onClick={() => {
            setdetail({});
            setOpen(true);
          }}
        />
      </div>
      <div className="max-w-[300px] mt-2 ">
        <InputBox
          drop
          value={selected}
          onChange={(v) => {
            getSliderList(v.target.value);
            setSelected(v.target.value);
          }}
        >
          {page_name.map((v, i) => (
            <option value={v}>{v}</option>
          ))}
        </InputBox>
      </div>
      {slider_img?.loading && slider_img?.list?.length != 0 ? (
        <Loader />
      ) : (
        <Table
          searchValue={search}
          onSearchChange={(v) => {
            setSearch(v.target.value);
          }}
          noData={slider_img?.list}
          headData={["S.No.", "Page Name", "Image", "Priority", "Actions"]}
        >
          {slider_img?.list
            // ?.filter((item) => {
            //   return (
            //     item.FULL_NAME.toLowerCase().includes(search.toLowerCase()) ||
            //     item.EMAIL_ADDRESS.toLowerCase().includes(
            //       search.toLowerCase()
            //     ) ||
            //     item.MOBILE.toLowerCase().includes(search.toLowerCase())
            //   );
            // })
            ?.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 text-center">{index + 1}</td>
                <td className="py-2 px-4 text-center">{item.PAGE_NAME}</td>

                <td className="py-4 px-4 text-center">
                  <div className="items-center flex justify-center">
                    <Image
                      src={process.env.REACT_APP_IMG_URL + item.IMAGE_URL}
                      width={100}
                      // className="h-[100px] w-[100px] object-contain"
                    />
                  </div>
                </td>
                <td className="py-2 px-4 text-center ">
                  <div className="flex justify-center">
                    <PriorityDrop
                      handleSelectChange={(event) => {
                        onUpdatePriority(item.ID, event.target.value);
                      }}
                      value={item.PRIORITY}
                      list={Array(slider_img?.list?.length + 1)
                        .fill("")
                        .map((v, i) => (
                          <option value={i}>{i}</option>
                        ))}
                    />
                  </div>
                </td>
                <td className="p-2 whitespace-nowrap ">
                  <div className="flex gap-2 justify-center">
                    <FiTrash
                      size={20}
                      className="cursor-pointer"
                      onClick={() => {
                        setdetail(item);
                        setOpen1(true);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </Table>
      )}
      <MyModal isOpen={open} setIsOpen={setOpen} title={"Add Category"}>
        <div className="p-5 w-full">
          <div className="flex w-full gap-4">
            <InputBox
              drop
              label={"Select Page Name"}
              value={selected}
              onChange={(v) => {
                setSelected(v.target.value);
              }}
            >
              {page_name.map((v, i) => (
                <option value={v}>{v}</option>
              ))}
            </InputBox>
            <FileInput
              doctype={".png,.jpg,.jpeg"}
              label={"Slider Image"}
              fileName={detail.img?.name}
              onChange={(v) => {
                handleImageUpload(v);
                setdetail({
                  ...detail,
                  img: v.target.files[0],
                  imgMess: "",
                });
              }}
              error={detail.imgMess}
            />
          </div>
          <div>
            <p className="mb-1">Select slider link</p>
            <div className="flex items-center gap-7">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={select_id == 1 ? true : false}
                  onChange={() => setSelect_id(1)}
                />
                <label className="text-sm font-normal">Category</label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={select_id == 2 ? true : false}
                  onChange={() => setSelect_id(2)}
                />
                <label className="text-sm font-normal">Sub Category</label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={select_id == 3 ? true : false}
                  onChange={() => setSelect_id(3)}
                />
                <label className="text-sm font-normal">Sub Category2</label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={select_id == 4 ? true : false}
                  onChange={() => setSelect_id(4)}
                />
                <label className="text-sm font-normal">Provider</label>
              </div>
            </div>
          </div>
          {select_id >= 1 && (
            <div className="flex w-full gap-4">
              <InputBox
                drop
                label={`Select Category`}
                value={cat_id}
                onChange={(v) => {
                  setCat_id(v.target.value);
                  get_sub_cat(v.target.value);
                }}
              >
                <option value={""}>Select Category</option>
                {category.list.map((v, i) => (
                  <option value={v.ID}>{v.C_NAME}</option>
                ))}
              </InputBox>
              {select_id >= 2 ? (
                <InputBox
                  drop
                  label={`Select Sub Category`}
                  value={sub_id}
                  onChange={(v) => {
                    setSub_id(v.target.value);
                  }}
                  error={sub_list?.length == 0 && "No Data Found"}
                >
                  <option value={""}>Select Sub Category</option>
                  {sub_list.map((v, i) => (
                    <option value={v.ID}>{v.S_NAME}</option>
                  ))}
                </InputBox>
              ) : (
                <div className="flex-1" />
              )}
            </div>
          )}
          {select_id >= 3 && (
            <div className="flex w-full gap-4">
              <InputBox
                drop
                label={`Select Sub Category 2`}
                value={sub2_id}
                onChange={(v) => {
                  setSub2_id(v.target.value);
                }}
                error={sub2_list?.length == 0 && "No Data Found"}
              >
                <option value={""}>Select Sub Category 2</option>
                {sub2_list.map((v, i) => (
                  <option value={v.ID}>{v.S_NAME}</option>
                ))}
              </InputBox>
              {select_id == 4 ? (
                <InputBox
                  drop
                  label={`Select Provider`}
                  value={provider_id}
                  onChange={(v) => {
                    setProvider_id(v.target.value);
                  }}
                  error={provider_list?.length == 0 && "No Data Found"}
                >
                  <option value={""}>Select Provider</option>
                  {provider_list.map((v, i) => (
                    <option value={v.USER_ID}>{v.PROFILE[0]?.FULL_NAME}</option>
                  ))}
                </InputBox>
              ) : (
                <div className="flex-1" />
              )}
            </div>
          )}

          <Center className={"mt-8"}>
            <Button title={"Submit"} onClick={onAdd} loading={loading} />
          </Center>
        </div>
      </MyModal>
      <MyPopup
        isOpen={open1}
        setIsOpen={setOpen1}
        title={"Delete"}
        message={"Are you sure? You want to delete data"}
        bname={"Delete"}
        onClick={() => onDelete()}
        loading={loading}
      ></MyPopup>
    </div>
  );
};

export default SliderImg;
