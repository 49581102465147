import React, { useContext, useEffect, useState } from "react";
import { FiEdit, FiPlus, FiTrash } from "react-icons/fi";
import {
  Button,
  Center,
  FileInput,
  Heading,
  InputBox,
  Loader,
  MyModal,
  MyPopup,
  PriorityDrop,
} from "../../utils/layoutUtils";
import Table from "../../components/Table";
import myContext from "../../data/myContext";
import { deleteApi, getApi, postApi } from "../../utils/apis";
import { toast } from "react-toastify";
import { Image, Switch } from "antd";

const Category = () => {
  const [open1, setOpen1] = useState(false);
  const [detail, setdetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [app_src, setApp_src] = useState(null);
  const [web_src, setWeb_src] = useState(null);
  const { getCategory, category } = useContext(myContext);

  const handleImageChange = (event, type) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        type == "web" ? setWeb_src(reader.result) : setApp_src(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onAdd = async () => {
    if (
      !detail?.C_NAME ||
      !detail?.DESCRIPTION ||
      (!edit && !detail?.app?.name) ||
      (!edit && !detail?.web?.name) ||
      !detail?.PRIORITY
    ) {
      toast.info("All fields are required", { toastId: 1 });
      return;
    }

    const body = new FormData();
    (!edit && Object.keys(detail.app)?.length != 0) ||
      body.append("APP", detail.app);
    (!edit && Object.keys(detail.web)?.length != 0) ||
      body.append("WEB", detail.web);
    body.append("C_NAME", detail.C_NAME);
    body.append("DESCRIPTION", detail.DESCRIPTION);
    body.append("PRIORITY", detail?.PRIORITY);
    edit && body.append("ID", detail.ID);

    setLoading(true);
    const res = await postApi(
      edit ? "categories/update" : "categories/add",
      body
    );
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      setOpen(false);
      getCategory();
      setWeb_src(null);
      setApp_src(null);
    } else {
      toast.error(res.message);
    }
  };
  useEffect(() => {
    getCategory();
  }, []);

  const onDelete = async () => {
    setLoading(true);
    const res = await deleteApi(`categories/${detail.ID}`);
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      setOpen1(false);
      getCategory();
    } else {
      toast.error(res.message);
    }
  };
  const onUpdatePriority = async (id, priority) => {
    setLoading(true);
    const res = await getApi(`categories/update/id-priority/${id}/${priority}`);
    setLoading(false);
    if (res.success) {
      getCategory();
      toast.success(res.message);
      setOpen1(false);
    } else {
      toast.error(res.message);
    }
  };
  const handleStatus = async (cat_id, e) => {
    setLoading(true);
    console.log(cat_id, e);
    const res = await getApi(`categories/enable-disable/${cat_id}`);
    setLoading(false);
    if (res.success) {
      getCategory();
      toast.success(res.message);
      setOpen1(false);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <div className="w-full block">
      <div className="flex justify-between items-center">
        <Heading title={"Categoty List"} />
        <Button
          title={"Add"}
          icon={<FiPlus size={20} />}
          onClick={() => {
            setWeb_src(null);
            setApp_src(null);
            setdetail({});
            setEdit(false);
            setOpen(true);
          }}
        />
      </div>
      {category?.loading && category?.list?.length != 0 ? (
        <Loader />
      ) : (
        <Table
          searchValue={search}
          onSearchChange={(v) => {
            setSearch(v.target.value);
          }}
          noData={category?.list}
          headData={[
            "S.No.",
            "Icon for App",
            "Icon for Web",
            "Category Name",
            "Description",
            "Priority",
            "Active",
            "Actions",
          ]}
        >
          {category?.list
            ?.filter((item) => {
              return item.C_NAME.toLowerCase().includes(search.toLowerCase());
            })
            ?.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 text-center">{index + 1}</td>
                <td className="py-4 px-4 text-center">
                  <div className="items-center flex justify-center">
                    <Image
                      src={process.env.REACT_APP_IMG_URL + item.LOGO_URL}
                      width={50}
                      // className="h-[20px] w-[20px] object-contain"
                    />
                  </div>
                </td>
                <td className="py-4 px-4 text-center">
                  <div className="items-center flex justify-center">
                    <Image
                      src={process.env.REACT_APP_IMG_URL + item.WEB_URL}
                      width={50}
                      // className="h-[20px] w-[20px] object-contain"
                    />
                  </div>
                </td>
                <td className="py-2 px-4 text-center">{item.C_NAME}</td>
                <td className="py-2 px-4 text-center">
                  <div className="line-clamp-4">{item.DESCRIPTION}</div>
                </td>
                <td className="py-2 px-4 text-center">
                  <PriorityDrop
                    handleSelectChange={(event) => {
                      onUpdatePriority(item.ID, event.target.value);
                    }}
                    value={item.PRIORITY}
                    list={Array(category?.list?.length + 1)
                      .fill("")
                      .map((v, i) => (
                        <option value={i}>{i}</option>
                      ))}
                  />
                </td>
                <td className="py-2 px-4 text-center">
                  <Switch
                    value={item?.ENABLED}
                    onChange={(e) => {
                      setdetail(item);
                      handleStatus(item.ID, e);
                    }}
                  />
                </td>
                <td className="p-2 whitespace-nowrap ">
                  <div className="flex gap-2 justify-center">
                    <FiEdit
                      size={20}
                      className="cursor-pointer"
                      onClick={() => {
                        setdetail(item);
                        setWeb_src(null);
                        setApp_src(null);
                        setEdit(true);
                        setOpen(true);
                      }}
                    />
                    <FiTrash
                      size={20}
                      className="cursor-pointer"
                      onClick={() => {
                        setdetail(item);
                        setOpen1(true);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </Table>
      )}
      <MyModal
        isOpen={open}
        setIsOpen={setOpen}
        title={edit ? "Edit Category" : "Add Category"}
      >
        <div className="p-5 w-full">
          <div className="flex flex-col lg:flex-row w-full gap-4 ">
            <InputBox
              label={"Category Name"}
              placeholder={"Category Name"}
              value={detail.C_NAME}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  C_NAME: v.target.value
                    .trimStart()
                    // .replace(/[^a-zA-Z0-9 ]/g, "")
                    // .replace(/^[0-9]/, "")
                    .replace(/\s{2,}/g, " "),
                  C_NAMEMess: "",
                });
              }}
              error={detail.C_NAMEMess}
            />
            <div className="flex flex-col gap-2 flex-1">
              <FileInput
                doctype={".png,.jpg,.jpeg"}
                label={"Icon for App"}
                fileName={detail.app?.name}
                onChange={(v) => {
                  handleImageChange(v, "app");
                  setdetail({
                    ...detail,
                    app: v.target.files[0],
                    appMess: "",
                  });
                }}
                error={detail.appMess}
              />
              {!app_src || <Image width={120} src={app_src} />}
            </div>
          </div>

          <div className="flex flex-col lg:flex-row w-full gap-4 ">
            <div className="flex flex-col gap-2 flex-1">
              <FileInput
                doctype={".png,.jpg,.jpeg"}
                label={"Icon for Web"}
                fileName={detail.web?.name}
                onChange={(v) => {
                  handleImageChange(v, "web");
                  setdetail({
                    ...detail,
                    web: v.target.files[0],
                    webMess: "",
                  });
                }}
                error={detail.webMess}
              />
              {!web_src || <Image width={120} src={web_src} />}
            </div>
            <InputBox
              area={true}
              label={"Description"}
              placeholder={"Description"}
              value={detail.DESCRIPTION}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  DESCRIPTION: v.target.value
                    .trimStart()
                    .replace(/\s{2,}/g, " "),
                  DESCRIPTIONMess: "",
                });
              }}
              error={detail.DESCRIPTIONMess}
            />
          </div>
          <div className="flex flex-col lg:flex-row w-full gap-4 ">
            <InputBox
              drop
              label={"Priority"}
              placeholder={"Priority"}
              value={detail.PRIORITY}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  PRIORITY: v.target.value,
                  PRIORITYMess: "",
                });
              }}
              error={detail.PRIORITYMess}
            >
              {Array(category?.list?.length + 2)
                .fill("")
                .map((v, i) => (
                  <option value={i}>{i}</option>
                ))}
            </InputBox>
            <div className="flex flex-1"></div>
          </div>
          <Center className={"mt-8"}>
            <Button
              title={edit ? "Update" : "Submit"}
              onClick={onAdd}
              loading={loading}
            />
          </Center>
        </div>
      </MyModal>
      <MyPopup
        isOpen={open1}
        setIsOpen={setOpen1}
        title={"Delete"}
        message={"Are you sure? You want to delete data"}
        bname={"Delete"}
        onClick={() => onDelete()}
        loading={loading}
      ></MyPopup>
    </div>
  );
};

export default Category;
