import React, { useContext, useEffect, useState } from "react";
import { FiEdit, FiPlus, FiTrash } from "react-icons/fi";
import {
  Button,
  Center,
  FileInput,
  Heading,
  InputBox,
  Loader,
  MyModal,
  MyPopup,
  PriorityDrop,
} from "../../utils/layoutUtils";
import Table from "../../components/Table";
import myContext from "../../data/myContext";
import { deleteApi, getApi, postApi, postApi1 } from "../../utils/apis";
import { toast } from "react-toastify";

const Plans = () => {
  const [open1, setOpen1] = useState(false);
  const [detail, setdetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const { getPlansList, plans } = useContext(myContext);

  const onAdd = async () => {
    if (
      !detail?.PLAN_NAME ||
      !detail?.PLAN_DESCRIPTION ||
      !detail?.PLAN_PRICE ||
      !detail?.PLAN_DURATION ||
      !detail?.LINE_1 ||
      !detail?.LINE_2 ||
      !detail?.LINE_3
    ) {
      toast.info("All fields are required", { toastId: 1 });
      return;
    }

    const body = {
      PLAN_NAME: detail.PLAN_NAME,
      PLAN_DESCRIPTION: detail.PLAN_DESCRIPTION,
      PLAN_PRICE: detail.PLAN_PRICE,
      PLAN_DURATION: detail.PLAN_DURATION,
      LINE_1: detail.LINE_1,
      LINE_2: detail.LINE_2,
      LINE_3: detail.LINE_3,
    };
    const body1 = {
      PLAN_NAME: detail.PLAN_NAME,
      PLAN_DESCRIPTION: detail.PLAN_DESCRIPTION,
      PLAN_PRICE: detail.PLAN_PRICE,
      PLAN_DURATION: detail.PLAN_DURATION,
      LINE_1: detail.LINE_1,
      LINE_2: detail.LINE_2,
      LINE_3: detail.LINE_3,
      ID: detail.ID,
    };

    setLoading(true);
    const res = await postApi1(
      edit ? "subcription-plan/update" : "subcription-plan/create",
      JSON.stringify(edit ? body1 : body)
    );
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      setOpen(false);
      getPlansList();
    } else {
      toast.error(res.message);
    }
  };
  useEffect(() => {
    getPlansList();
  }, []);

  const onDelete = async () => {
    setLoading(true);
    const res = await deleteApi(`subcription-plan/delete-plan-id/${detail.ID}`);
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      setOpen1(false);
      getPlansList();
    } else {
      toast.error(res.message);
    }
  };
  const onUpdatePriority = async (id, priority) => {
    setLoading(true);
    const res = await getApi(`categories/update/id-priority/${id}/${priority}`);
    setLoading(false);
    if (res.success) {
      getPlansList();
      toast.success(res.message);
      setOpen1(false);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <div className="w-full block">
      <div className="flex justify-between items-center">
        <Heading title={"Plans & Pricing List"} />
        <Button
          title={"Add"}
          icon={<FiPlus size={20} />}
          onClick={() => {
            setdetail({});
            setEdit(false);
            setOpen(true);
          }}
        />
      </div>
      {plans?.loading && plans?.list?.length != 0 ? (
        <Loader />
      ) : (
        <Table
          searchValue={search}
          onSearchChange={(v) => {
            setSearch(v.target.value);
          }}
          noData={plans?.list}
          headData={[
            "S.No.",
            "Plan Name",
            "PLAN_PRICE",
            "Description",
            "PLAN_DURATION",
            "LINE_1",
            "LINE_2",
            "LINE_3",
            "Actions",
          ]}
        >
          {plans?.list
            ?.filter((item) => {
              return item.PLAN_NAME.toLowerCase().includes(
                search.toLowerCase()
              );
            })
            ?.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 text-center">{index + 1}</td>
                <td className="py-2 px-4 text-center">{item.PLAN_NAME}</td>
                <td className="py-2 px-4 text-center">{item.PLAN_PRICE}</td>
                <td className="py-2 px-4 text-center">
                  <div className="line-clamp-4">{item.PLAN_DESCRIPTION}</div>
                </td>
                <td className="py-2 px-4 text-center">{item.PLAN_DURATION}</td>
                <td className="py-2 px-4 text-center">{item.LINE_1}</td>
                <td className="py-2 px-4 text-center">{item.LINE_2}</td>
                <td className="py-2 px-4 text-center">{item.LINE_3}</td>

                <td className="p-2 whitespace-nowrap ">
                  <div className="flex gap-2 justify-center">
                    <FiEdit
                      size={20}
                      className="cursor-pointer"
                      onClick={() => {
                        setdetail(item);
                        setEdit(true);
                        setOpen(true);
                      }}
                    />
                    <FiTrash
                      size={20}
                      className="cursor-pointer"
                      onClick={() => {
                        setdetail(item);
                        setOpen1(true);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </Table>
      )}
      <MyModal
        isOpen={open}
        setIsOpen={setOpen}
        title={edit ? "Edit Category" : "Add Category"}
      >
        <div className="p-5 w-full">
          <div className="flex w-full gap-4">
            <InputBox
              label={"Plan Name"}
              placeholder={"Plan Name"}
              value={detail.PLAN_NAME}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  PLAN_NAME: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                  PLAN_NAMEMess: "",
                });
              }}
              error={detail.PLAN_NAMEMess}
            />
            <InputBox
              label={"Plan Price"}
              placeholder={"Plan Price"}
              value={detail.PLAN_PRICE}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  PLAN_PRICE: v.target.value.trim().replace(/[^0-9 ]/g, ""),
                  PLAN_PRICEMess: "",
                });
              }}
              error={detail.PLAN_PRICEMess}
            />
          </div>

          <div className="flex w-full gap-4">
            <InputBox
              area={true}
              label={"Description"}
              placeholder={"Description"}
              value={detail.PLAN_DESCRIPTION}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  PLAN_DESCRIPTION: v.target.value.trimStart(),

                  PLAN_DESCRIPTIONMess: "",
                });
              }}
              error={detail.PLAN_DESCRIPTIONMess}
            />
            <InputBox
              disabled={edit}
              label={"Plan Duration (In Days)"}
              placeholder={"Plan Duration"}
              value={detail.PLAN_DURATION}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  PLAN_DURATION: v.target.value.trim().replace(/[^0-9 ]/g, ""),
                  PLAN_DURATIONMess: "",
                });
              }}
              error={detail.PLAN_DURATIONMess}
            />
          </div>
          <div className="flex w-full gap-4">
            <InputBox
              label={"Plan Line1"}
              placeholder={"Plan Line1"}
              value={detail.LINE_1}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  LINE_1: v.target.value.trimStart(),

                  LINE_1Mess: "",
                });
              }}
              error={detail.LINE_1Mess}
            />
            <InputBox
              label={"Plan Line2"}
              placeholder={"Plan Line2"}
              value={detail.LINE_2}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  LINE_2: v.target.value.trimStart(),
                  LINE_2Mess: "",
                });
              }}
              error={detail.LINE_2Mess}
            />
          </div>
          <div className="flex w-full gap-4">
            <InputBox
              label={"Plan Line3"}
              placeholder={"Plan Line3"}
              value={detail.LINE_3}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  LINE_3: v.target.value.trimStart(),
                  LINE_3Mess: "",
                });
              }}
              error={detail.LINE_3Mess}
            />
            <div className="flex-1" />
          </div>

          <Center className={"mt-8"}>
            <Button
              title={edit ? "Update" : "Submit"}
              onClick={onAdd}
              loading={loading}
            />
          </Center>
        </div>
      </MyModal>
      <MyPopup
        isOpen={open1}
        setIsOpen={setOpen1}
        title={"Delete"}
        message={"Are you sure? You want to delete data"}
        bname={"Delete"}
        onClick={() => onDelete()}
        loading={loading}
      ></MyPopup>
    </div>
  );
};

export default Plans;
