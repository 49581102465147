import React, { useEffect, useState } from "react";
import MyContext from "./myContext";
import { toast } from "react-toastify";

function MyState(props) {
  const user = JSON.parse(localStorage.getItem("mycall_book"));
  const [category, setCategory] = useState({ loading: false, list: [] });
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState({
    loading: false,
    list: [],
  });
  const [subCat2, setSubCat2] = useState({
    loading: false,
    list: [],
  });
  const [provider, setProvider] = useState({
    loading: false,
    list: [],
  });
  const [provider_all, setProvider_all] = useState({
    loading: false,
    list: [],
  });
  const [users, setUsers] = useState({
    loading: false,
    list: [],
  });
  const [plans, setPlans] = useState({
    loading: false,
    list: [],
  });
  const [query_list, setQuery_list] = useState({
    loading: false,
    list: [],
  });
  const [blogs_list, setBlogs_list] = useState({
    loading: false,
    list: [],
  });
  const [notification_list, setNotification_list] = useState({
    loading: false,
    list: [],
  });
  const [slider_img, setSlider_img] = useState({
    loading: false,
    list: [],
  });
  const [blog_status, setBlog_status] = useState({
    loading: false,
    data: {},
  });
  const [user_report, setUser_report] = useState({
    loading: false,
    data: [],
  });
  const [logo_data, setlogo_data] = useState({
    loading: false,
    list: [],
  });

  const getCategory = async () => {
    try {
      setCategory({ ...category, loading: true });
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "categories/list",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      const res = await responce.json();
      setCategory({ ...category, loading: false });
      if (res.success) {
        setCategory({ ...category, list: res.data });
      } else {
        setCategory({ ...category, list: [] });
      }
    } catch (error) {
      setCategory({ ...category, loading: false });
    }
  };
  const getServices = async (id) => {
    try {
      setServices({ ...services, loading: true });
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + `services/list/cat_id/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      const res = await responce.json();
      setServices({ ...services, loading: false });
      if (res.success) {
        setServices({ ...services, list: res.data });
      } else {
        setServices({ ...services, list: [] });
      }
    } catch (error) {
      setServices({ ...services, loading: false });
    }
  };
  const getSubCat2 = async (id) => {
    console.log(id, "di");
    try {
      setSubCat2({ ...subCat2, loading: true });
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + `services1/list/ser_id/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      const res = await responce.json();
      console.log("subcat2", res);
      setSubCat2({ ...subCat2, loading: false });
      if (res.success) {
        setSubCat2({ ...subCat2, list: res.data });
      } else {
        setSubCat2({ ...subCat2, list: [] });
      }
    } catch (error) {
      setSubCat2({ ...subCat2, loading: false });
    }
  };
  const getProviderList = async () => {
    try {
      setProvider({ ...provider, loading: true });
      const body = JSON.stringify({
        LIMTI: 10,
        PAGE: 1,
        STATUS: 0,
      });
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "service-info/list",
        {
          method: "POST",
          body: body,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      const res = await responce.json();
      setProvider({ ...provider, loading: false });
      if (res.success) {
        setProvider({ ...provider, list: res.data });
      } else {
        setProvider({ ...provider, list: [] });
      }
    } catch (error) {
      setProvider({ ...provider, loading: false });
    }
  };
  const getUsersList = async () => {
    try {
      setUsers({ ...users, loading: true });
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "user/user-list?page=1&listPerPage=15",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      const res = await responce.json();
      console.log(res);
      setUsers({ ...users, loading: false });
      if (res.success) {
        setUsers({ ...users, list: res.data });
      } else {
        setUsers({ ...users, list: [] });
      }
    } catch (error) {
      setUsers({ ...users, loading: false });
    }
  };
  const getProvidersList = async () => {
    try {
      setProvider_all({ ...provider_all, loading: true });
      const body = JSON.stringify({
        LIMTI: 10,
        PAGE: 1,
        STATUS: 1,
      });
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "service-info/list",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.TOKEN}`,
          },
          body: body,
        }
      );
      const res = await responce.json();
      console.log("provoider", res);
      setProvider_all({ ...provider_all, loading: false });
      if (res.success) {
        setProvider_all({ ...provider_all, list: res.data });
      } else {
        setProvider_all({ ...provider_all, list: [] });
      }
    } catch (error) {
      setProvider_all({ ...provider_all, loading: false });
    }
  };
  const getPlansList = async () => {
    try {
      setPlans({ ...plans, loading: true });
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "subcription-plan/list",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      const res = await responce.json();
      setPlans({ ...plans, loading: false });
      if (res.success) {
        setPlans({ ...plans, list: res.data });
      } else {
        setPlans({ ...plans, list: [] });
      }
    } catch (error) {
      setPlans({ ...plans, loading: false });
    }
  };
  const getQueryList = async () => {
    try {
      setQuery_list({ ...query_list, loading: true });
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "questions-direct/list",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      const res = await responce.json();
      setQuery_list({ ...query_list, loading: false });
      if (res.success) {
        setQuery_list({ ...query_list, list: res.data });
      } else {
        setQuery_list({ ...query_list, list: [] });
      }
    } catch (error) {
      setQuery_list({ ...query_list, loading: false });
    }
  };
  const getBlogsList = async () => {
    try {
      setBlogs_list({ ...blogs_list, loading: true });
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "blogs/list",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      const res = await responce.json();
      setBlogs_list({ ...blogs_list, loading: false });
      if (res.success) {
        setBlogs_list({ ...blogs_list, list: res.data });
      } else {
        setBlogs_list({ ...blogs_list, list: [] });
      }
    } catch (error) {
      setBlogs_list({ ...blogs_list, loading: false });
    }
  };
  const getNotificationList = async () => {
    try {
      setNotification_list({ ...notification_list, loading: true });
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "notification/list",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      const res = await responce.json();
      setNotification_list({ ...notification_list, loading: false });
      if (res.success) {
        setNotification_list({ ...notification_list, list: res.data });
      } else {
        setNotification_list({ ...notification_list, list: [] });
      }
    } catch (error) {
      setNotification_list({ ...notification_list, loading: false });
    }
  };
  const getSliderList = async (name) => {
    try {
      setSlider_img({ ...slider_img, loading: true });
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + `slider-info/pagename/${name}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      const res = await responce.json();
      setSlider_img({ ...slider_img, loading: false });
      if (res.success) {
        setSlider_img({ ...slider_img, list: res.data });
      } else {
        setSlider_img({ ...slider_img, list: [] });
      }
    } catch (error) {
      setSlider_img({ ...slider_img, loading: false });
    }
  };
  const getCheckBlogStatus = async () => {
    try {
      setBlog_status({ ...blog_status, loading: true });
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + `blog-status/check-status`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      const res = await responce.json();
      setBlog_status({ ...blog_status, loading: false });
      if (res.success) {
        setBlog_status({ ...blog_status, data: res.data });
      } else {
        setBlog_status({ ...blog_status, data: {} });
      }
    } catch (error) {
      setBlog_status({ ...blog_status, loading: false });
    }
  };
  const getUserReports = async (sd, ed) => {
    try {
      setUser_report({ ...user_report, loading: true });
      const responce = await fetch(
        process.env.REACT_APP_API_KEY +
          `report/user?from_date=${sd}&to_date=${ed}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      const res = await responce.json();
      setUser_report({ ...user_report, loading: false });
      if (res.success) {
        setUser_report({ ...user_report, data: res.data });
      } else {
        setUser_report({ ...user_report, data: [] });
      }
    } catch (error) {
      setUser_report({ ...user_report, loading: false });
    }
  };
  const getLogoData = async (sd, ed) => {
    try {
      setlogo_data({ ...logo_data, loading: true });
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + `blogs/logo-list`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      const res = await responce.json();
      setlogo_data({ ...logo_data, loading: false });
      if (res.success) {
        setlogo_data({ ...logo_data, list: res.data });
      } else {
        setlogo_data({ ...logo_data, list: [] });
      }
    } catch (error) {
      setlogo_data({ ...logo_data, loading: false });
    }
  };
  useEffect(() => {
    getProvidersList();
    getCategory();
    getServices(category?.list[0]?.ID);
    getProviderList();
    getUsersList();
    getPlansList();
    getQueryList();
    getBlogsList();
    getNotificationList();
    getSliderList("Home Top");
    getCheckBlogStatus();
    getLogoData();
  }, []);

  return (
    <MyContext.Provider
      value={{
        category,
        getCategory,
        loading,
        services,
        getServices,
        getProviderList,
        provider,
        getUsersList,
        users,
        getProvidersList,
        provider_all,
        subCat2,
        getSubCat2,
        getPlansList,
        plans,
        getQueryList,
        query_list,
        getBlogsList,
        blogs_list,
        getNotificationList,
        notification_list,
        getSliderList,
        slider_img,
        blog_status,
        setBlog_status,
        getCheckBlogStatus,
        getUserReports,
        user_report,
        getLogoData,
        logo_data,
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
}

export default MyState;
