import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";

const Layout = () => {
  return (
    <div className="flex h-screen flex-col w-screen">
      {/* Header */}
      <header className="sticky top-0 bg-[#fff]  py-2 px-8 w-full flex shadow-lg">
        <Header />
      </header>

      {/* Main content */}
      <div className="flex overflow-hidden h-full ">
        {/* Sidebar */}
        <div className="w-1/8 bg-[#fff] sticky h-full min-h-full flex overflow-y-auto shadow-lg ">
          <Sidebar />
        </div>

        {/* Main content */}
        <div className="p-8 overflow-y-auto w-full bg-[#f2f7fb]">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
