import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";

const User = ({
  user_list = [],
  provider_list = [],
  user_data,
  provider_data,
}) => {
  console.log(user_data);
  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  const data1 = [
    {
      date: "2024-05-03",
      count: 6,
    },
    {
      date: "2024-05-04",
      count: 4,
    },
    {
      date: "2024-05-05",
      count: 7,
    },
    {
      date: "2024-05-06",
      count: 10,
    },
    {
      date: "2024-05-07",
      count: 0,
    },
    {
      date: "2024-05-08",
      count: 3,
    },
    {
      date: "2024-05-09",
      count: 7,
    },
    {
      date: "2024-05-10",
      count: 1,
    },
  ];
  return (
    <div className="w-full flex h-[500px] gap-4">
      <div className="h-full flex-1 flex bg-white p-4 rounded-lg flex-col gap-3">
        <div>User Report</div>
        <div>Total User Count : {user_data?.totalUserCount}</div>

        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={user_list}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            barSize={20}
          >
            <XAxis
              dataKey="date"
              scale="point"
              padding={{ left: 10, right: 10 }}
              //   className="text-[12px]"
              fontSize={12}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid strokeDasharray="3 3" />
            <Bar dataKey="count" fill="#8884d8" background={{ fill: "#eee" }} />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="h-full flex-1 flex bg-white p-4 rounded-lg flex-col gap-3">
        <div>Provider Report</div>
        <div>Total Provider Count : {provider_data?.totalProviderCount}</div>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={provider_list}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" fontSize={12} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="count"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default User;
