import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";
import Layout from "./Layout";
import Category from "./pages/category";
import Dashboard from "./pages/dashboard";
import Users from "./pages/users";
import Notification from "./pages/notification";
import Blogs from "./pages/blogs";
import MyState from "./data/myState";
import SubCategory from "./pages/subcategory";
import Provider_data from "./pages/provider_data";
import ProviderList from "./pages/provider_data/ProviderList.jsx";
import { useEffect } from "react";
import SubCategory2 from "./pages/subcategory2/index.jsx";
import Plans from "./pages/plans/Plans.jsx";
import QueryList from "./pages/query/index.jsx";
import SliderImg from "./pages/sliderImg/index.jsx";
import DynamicLogo from "./pages/logo/index.jsx";

function App() {
  const user = JSON.parse(localStorage.getItem("mycall_book"));
  const navigate = useNavigate();
  useEffect(() => {
    if (user == undefined) {
      navigate("/login");
    }
  }, []);

  return (
    <MyState>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="/categories" element={<Category />} />
            <Route path="/sub-category" element={<SubCategory />} />
            <Route path="/sub-category2" element={<SubCategory2 />} />
            <Route path="/users" element={<Users />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/plans-pricing" element={<Plans />} />
            <Route path="/users-query" element={<QueryList />} />
            <Route path="/slider-images" element={<SliderImg />} />
            <Route path="/new-provider-list" element={<Provider_data />} />
            <Route path="/provider-list" element={<ProviderList />} />
            <Route path="/dynamic-logo" element={<DynamicLogo />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </MyState>
  );
}

export default App;

const ProtectedRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("mycall_book"));

  if (user) {
    return <Outlet />;
  } else {
    return <Navigate to={"/login"} />;
  }
};
