import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdAddPhotoAlternate, MdDashboard } from "react-icons/md";
import { FaUsers, FaRegBell } from "react-icons/fa";
import { BiCategory } from "react-icons/bi";
import { GrServices } from "react-icons/gr";
import { GiPriceTag } from "react-icons/gi";
import { MdFeed } from "react-icons/md";
import { TbDeviceMobileQuestion } from "react-icons/tb";
import { BsCardImage } from "react-icons/bs";
import { LuImagePlus } from "react-icons/lu";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const c_tab = location.pathname.split("/")[1];
  return (
    <div className="flex w-full gap-2 flex-col z-0">
      {/* <div className="flex items-center gap-3 justify-center">
        <img
          src={logo}
          style={{ height: 50, width: 50, objectFit: "contain" }}
        />
        <p className="font-sans font-semibold text-[20px]">MyCall Book</p>
      </div> */}
      <Tab
        current={c_tab == "" ? true : false}
        onClick={() => navigate("/")}
        icon={<MdDashboard size={20} color="#44195E" />}
      >
        Dashboard
      </Tab>
      <Tab
        current={c_tab == "dynamic-logo" ? true : false}
        onClick={() => navigate("/dynamic-logo")}
        icon={<LuImagePlus size={20} color="#44195E" />}
      >
        Logo
      </Tab>
      <Tab
        current={c_tab == "users" ? true : false}
        onClick={() => navigate("/users")}
        icon={<FaUsers size={20} color="#44195E" />}
      >
        Users
      </Tab>
      <Tab
        current={c_tab == "provider-list" ? true : false}
        onClick={() => navigate("/provider-list")}
        icon={<FaUsers size={20} color="#44195E" />}
      >
        Service Provider List
      </Tab>
      <Tab
        current={c_tab == "slider-images" ? true : false}
        onClick={() => navigate("/slider-images")}
        icon={<BsCardImage size={20} color="#44195E" />}
      >
        Slider Image
      </Tab>
      <Tab
        current={c_tab == "categories" ? true : false}
        onClick={() => navigate("/categories")}
        icon={<BiCategory size={20} color="#44195E" />}
      >
        Category
      </Tab>
      <Tab
        current={c_tab == "sub-category" ? true : false}
        onClick={() => navigate("/sub-category")}
        icon={<BiCategory size={20} color="#44195E" />}
      >
        Sub Category
      </Tab>
      <Tab
        current={c_tab == "sub-category2" ? true : false}
        onClick={() => navigate("/sub-category2")}
        icon={<BiCategory size={20} color="#44195E" />}
      >
        Sub Category2
      </Tab>

      <Tab
        current={c_tab == "plans-pricing" ? true : false}
        onClick={() => navigate("/plans-pricing")}
        icon={<GiPriceTag size={20} color="#44195E" />}
      >
        Plans & Pricing
      </Tab>
      <Tab
        current={c_tab == "users-query" ? true : false}
        onClick={() => navigate("/users-query")}
        icon={<TbDeviceMobileQuestion size={20} color="#44195E" />}
      >
        Users Query
      </Tab>
      <Tab
        current={c_tab == "blogs" ? true : false}
        onClick={() => navigate("/blogs")}
        icon={<MdFeed size={20} color="#44195E" />}
      >
        Blogs
      </Tab>
      <Tab
        current={c_tab == "notification" ? true : false}
        onClick={() => navigate("/notification")}
        icon={<FaRegBell size={20} color="#44195E" />}
      >
        Notification
      </Tab>
      <Tab
        current={c_tab == "new-provider-list" ? true : false}
        onClick={() => navigate("/new-provider-list")}
        icon={<GrServices size={25} color="#44195E" />}
      >
        New Service Provider Request
      </Tab>
    </div>
  );
};
const Tab = ({ onClick, children, current, icon }) => {
  return (
    <div
      onClick={onClick}
      className={`font-medium items-center gap-3 text-[16px] flex py-2 px-4 self-start cursor-pointer w-full  text-[#44195E] hover:bg-[#655ADF33]`}
      style={{ backgroundColor: current && "#655ADF33" }}
      //   bg-[#655ADF]
    >
      {icon}
      {children}
    </div>
  );
};

export default Sidebar;
// FE9340
