import jsPDF from "jspdf";
import "jspdf-autotable";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

// export const base_url = "http://localhost:8000/api/";

export const base_url = "https://api.smartdocs365.com/api/";

const loggedInUser = localStorage.getItem("userId");
const user = JSON.parse(loggedInUser);

export const ID = user !== null ? user?.userid : "";

export const isValidEmail = /\S+@\S+\.\S+/;
// export const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

export const exportPDF = ({
  table_headers,
  table_data,
  tableName,
  fileName,
}) => {
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);

  const title = { tableName };
  //   const title = "SD 365";
  //   const headers = [
  //     ["S.No.", "Name", "Email", "Mobile", "Platform", "Policies", "Document"],
  //   ];
  const headers = [table_headers];
  const data = table_data;
  //   const data = users_list.map((elt, i) => [
  //     i + initialValue,
  //     elt.Name,
  //     elt.Email,
  //     elt.mobile,
  //     elt.platform,
  //     elt.totalpolicies,
  //     elt.totaldocument,
  //   ]);

  let content = {
    startY: 50,
    head: headers,
    body: data,
  };

  doc.text(tableName, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`${fileName}.pdf`);
};

//   const headers = [
//     { label: "S.No.", key: "no" },
//     { label: "FULL_NAME", key: "name" },
//     { label: "EMAIL_ADDRESS", key: "email" },
//     { label: "PHONE_NUMBER", key: "mobile" },
//     { label: "ADDRESS", key: "platform" },
//     { label: "SHOP_ADDR", key: "document" },
//     { label: "EXPERIENCE", key: "document" },
//   ];
//   const data = provider_all?.list?.map((elt, i) => ({
//     no: i + 1,
//     name: elt.PROFILE?.FULL_NAME,
//     email: elt.email_address,
//     mobile: elt.mobile,
//     policies: 10,
//   }));
export const handleExcel = (data, fileName) => {
  if (data?.length > 0) {
    const ws = XLSX.utils.json_to_sheet(data.map((v) => v));

    // Check if ws["!ref"] is defined before using it
    const firstRowRange = ws["!ref"]
      ? XLSX.utils.decode_range(ws["!ref"])
      : null;

    if (firstRowRange) {
      for (let col = firstRowRange.s.c; col <= firstRowRange.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({
          r: firstRowRange.s.r,
          c: col,
        });
        ws[cellAddress].s = { fill: { fgColor: { rgb: "DDDDDD" } } }; // Gray color
      }

      for (let col = firstRowRange.s.c; col <= firstRowRange.e.c; col++) {
        let maxCellLength = 30;

        for (let row = firstRowRange.s.r + 1; row <= firstRowRange.e.r; row++) {
          const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
          const cellValue = ws[cellAddress] ? ws[cellAddress].w || "" : "";
          maxCellLength = Math.max(maxCellLength, cellValue.length);
        }

        const colWidth = maxCellLength + 1; // You can adjust this value based on your needs
        ws["!cols"] = ws["!cols"] || [];
        ws["!cols"][col] = { width: colWidth };
      }

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
        mimeType: "application/octet-stream",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(blob, `${fileName}.xlsx`);
    } else {
      toast.error("Please Add Some Item");
    }
  }
};

export function getOneMonthBefore(date) {
  const currentDate = new Date(date);
  const oneMonthBefore = new Date(
    currentDate.setMonth(currentDate.getMonth() - 1)
  );
  return oneMonthBefore;
}

// value1={plan_details?.planName?.trim()}
//           value2={plan_details?.totalPdfLimit}
