// import React, { useState } from "react";
// import { GoChevronDown } from "react-icons/go";
// import "tailwindcss/tailwind.css"; // Ensure Tailwind CSS is imported

// const CustomMenu = () => {
//   const [menuOpen, setMenuOpen] = useState(false);

//   return (
//     // <div className="relative inline-block text-left">
//     //   <div className="group">
//     //     <button
//     //       type="button"
//     //       className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
//     //     >
//     //       Options
//     //     </button>
//     //     <div className="absolute hidden group-hover:block origin-top-right right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
//     //       <div
//     //         className="py-1"
//     //         role="menu"
//     //         aria-orientation="vertical"
//     //         aria-labelledby="options-menu"
//     //       >
//     //         <div className="relative group">
//     //           <button
//     //             className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
//     //             role="menuitem"
//     //           >
//     //             Option 1
//     //           </button>
//     //           <div className="absolute left-full top-0 mt-1 w-48 rounded-md shadow-lg bg-white hidden group-hover:block">
//     //             <div className="py-1">
//     //               <a
//     //                 href="#"
//     //                 className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
//     //                 role="menuitem"
//     //               >
//     //                 Sub Option 1
//     //               </a>
//     //               <a
//     //                 href="#"
//     //                 className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
//     //                 role="menuitem"
//     //               >
//     //                 Sub Option 2
//     //               </a>
//     //             </div>
//     //           </div>
//     //         </div>
//     //         <a
//     //           href="#"
//     //           className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
//     //           role="menuitem"
//     //         >
//     //           Option 2
//     //         </a>
//     //         <a
//     //           href="#"
//     //           className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
//     //           role="menuitem"
//     //         >
//     //           Option 3
//     //         </a>
//     //       </div>
//     //     </div>
//     //   </div>
//     // </div>
//     // <div className="relative inline-block text-left">
//     //   <div>
//     //     <button
//     //       type="button"
//     //       className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
//     //       //   onClick={() => setMenuOpen(!menuOpen)}
//     //       onMouseEnter={() => setMenuOpen(true)}
//     //       onMouseLeave={() => setMenuOpen(false)}
//     //     >
//     //       Options
//     //     </button>
//     //   </div>

//     //   {menuOpen && (
//     //     <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
//     //       <div
//     //         className="py-1"
//     //         role="menu"
//     //         aria-orientation="vertical"
//     //         aria-labelledby="options-menu"
//     //       >
//     //         <div className="relative group">
//     //           <button
//     //             className="flex justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
//     //             role="menuitem"
//     //           >
//     //             Option 1 <GoChevronDown color="#454545" size={20} />
//     //           </button>
//     //           <div className="absolute left-full top-0 mt-1 w-48 rounded-md shadow-lg bg-white hidden group-hover:block">
//     //             <div className="py-1">
//     //               <a
//     //                 href="#"
//     //                 className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
//     //                 role="menuitem"
//     //               >
//     //                 Sub Option 1
//     //               </a>
//     //               <a
//     //                 href="#"
//     //                 className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
//     //                 role="menuitem"
//     //               >
//     //                 Sub Option 2
//     //               </a>
//     //             </div>
//     //           </div>
//     //         </div>
//     //         <a
//     //           href="#"
//     //           className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
//     //           role="menuitem"
//     //         >
//     //           Option 2
//     //         </a>
//     //         <a
//     //           href="#"
//     //           className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
//     //           role="menuitem"
//     //         >
//     //           Option 3
//     //         </a>
//     //       </div>
//     //     </div>
//     //   )}
//     // </div>
//   );
// };

// // const App = () => (
// //   <div className="flex items-center justify-center h-screen">
// //     <Menu />
// //   </div>
// // );

// // export default App;

// export default CustomMenu;

import React, { useContext, useEffect, useRef, useState } from "react";
import { GoChevronDown, GoChevronRight } from "react-icons/go";
import myContext from "../data/myContext";

const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
};
const CustomMenu = ({ title, setTitle }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const { getProvidersList, provider_all, category } = useContext(myContext);

  useOutsideClick(menuRef, () => setMenuOpen(false));

  return (
    <div className="relative inline-block text-left" ref={menuRef}>
      <div>
        <button
          type="button"
          className="min-w-[150px] inline-flex justify-between w-full gap-3 rounded-md border border-gray-300 shadow-sm px-2 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {title || "filter"} <GoChevronDown size={20} color="#000" />
        </button>
      </div>

      {menuOpen && (
        <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <div className="relative group">
              <button
                className="flex justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                role="menuitem"
              >
                Gender <GoChevronRight size={20} color="#000" />
              </button>
              <div className="absolute left-full top-0 mt-1 w-48 rounded-md shadow-lg bg-white hidden group-hover:block">
                <div className="py-1">
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                  >
                    Male
                  </a>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                  >
                    Female
                  </a>
                </div>
              </div>
            </div>
            <div className="relative group">
              <button
                className="flex justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                role="menuitem"
              >
                Location <GoChevronRight size={20} color="#000" />
              </button>
              <div className="absolute left-full top-0 mt-1 w-48 rounded-md shadow-lg bg-white hidden group-hover:block">
                <div className="py-1">
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                  >
                    Male
                  </a>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                  >
                    Female
                  </a>
                </div>
              </div>
            </div>
            <div className="relative group">
              <button
                className="flex justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                role="menuitem"
              >
                Category <GoChevronRight size={20} color="#000" />
              </button>
              <div className="absolute left-full max-h-[200px] overflow-y-auto top-0 mt-1 w-48 rounded-md shadow-lg bg-white hidden group-hover:block">
                <div className="py-1">
                  {category?.list?.map((v, i) => (
                    <div
                      onClick={() => {
                        setTitle(v.C_NAME);
                        setMenuOpen(false);
                      }}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                    >
                      {v.C_NAME}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomMenu;
