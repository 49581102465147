import React, { useContext, useEffect, useState } from "react";
import { FiEdit, FiPlus, FiTrash } from "react-icons/fi";
import {
  Button,
  Heading,
  Loader,
  MyModal,
  MyPopup,
  Toggle,
  ViewData,
} from "../../utils/layoutUtils";
import Table from "../../components/Table";
import myContext from "../../data/myContext";
import { deleteApi, getApi, postApi, postApi1, putApi } from "../../utils/apis";
import { toast } from "react-toastify";
import { exportPDF, handleExcel } from "../../utils/utils";
import { Switch } from "antd";

const Users = () => {
  const [open, setOpen] = useState(false);
  const [detail, setdetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const { getUsersList, users } = useContext(myContext);

  useEffect(() => {
    getUsersList();
  }, []);

  const change_status = async (status, id) => {
    setLoading(true);
    const raw = JSON.stringify({
      USER_ID: id,
      STATUS: status,
    });
    const res = await putApi("user/block", raw);
    setLoading(false);
    if (res.success) {
      getUsersList();
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  const table_data = users?.list?.map((elt, i) => [
    i + 1,
    elt.userInfo[0]?.FULL_NAME,
    elt.userInfo[0]?.EMAIL_ADDRESS,
    elt.userInfo[0]?.PHONE_NUMBER,
  ]);

  const export_pdf = () => {
    exportPDF({
      table_data: table_data,
      table_headers: ["S.No.", "Full Name", "Email Address", "Phone Number"],
      fileName: "users-list",
      tableName: "MyCall Book Users List",
    });
  };
  return (
    <div className="w-full block">
      <div className="flex justify-between items-center">
        <Heading title={"Users List"} />
      </div>
      {users?.loading && users?.list?.length != 0 ? (
        <Loader />
      ) : (
        <Table
          topleft={
            <div className="flex gap-2">
              <Button title={"PDF Export"} onClick={() => export_pdf()} />
              <Button
                title={"XLSX Export"}
                onClick={() => handleExcel(users?.list, "users-list")}
              />
            </div>
          }
          searchValue={search}
          onSearchChange={(v) => {
            setSearch(v.target.value);
          }}
          noData={users?.list}
          headData={[
            "S.No.",
            "Block",
            "Full Name",
            "Gender",
            "Email Address",
            "Phone Number",
          ]}
        >
          {users?.list
            ?.filter(
              (item) =>
                item.userInfo[0]?.FULL_NAME?.toLowerCase().includes(
                  search.toLowerCase()
                ) ||
                item.userInfo[0]?.EMAIL_ADDRESS?.toLowerCase().includes(
                  search.toLowerCase()
                ) ||
                item.userInfo[0]?.PHONE_NUMBER?.toString().includes(
                  search.toLowerCase()
                )
            )
            ?.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 text-center">{index + 1}</td>
                <td className="py-2 px-4 text-center">
                  <Switch
                    value={item?.userInfo[0]?.BLOCKED}
                    onChange={(e) => change_status(e, item.USER_ID)}
                  />
                </td>
                <td
                  className="py-2 px-4 text-center font-sans cursor-pointer text-indigo-500"
                  onClick={() => {
                    setdetail(item?.userInfo[0]);
                    setOpen(true);
                  }}
                >
                  {item?.userInfo[0]?.FULL_NAME}
                </td>
                <td className="py-2 px-4 text-center font-sans ">
                  {item?.userInfo[0]?.GENDER}
                </td>
                <td className="py-2 px-4 text-center font-sans ">
                  {item?.userInfo[0]?.EMAIL_ADDRESS}
                </td>
                <td className="py-2 px-4 text-center font-sans ">
                  {item?.userInfo[0]?.PHONE_NUMBER}
                </td>
              </tr>
            ))}
        </Table>
      )}
      <MyModal isOpen={open} setIsOpen={setOpen} title={"User Details"}>
        <div className="p-5">
          <ViewData title={"FULL_NAME"} value={detail?.FULL_NAME} />
          <ViewData title={"Gender"} value={detail?.GENDER} />
          <ViewData title={"EMAIL_ADDRESS"} value={detail?.EMAIL_ADDRESS} />
          <ViewData title={"PHONE_NUMBER"} value={detail?.PHONE_NUMBER} />

          <ViewData title={"User Profile"} img={detail?.IMAGE_URL} />
        </div>
      </MyModal>
    </div>
  );
};

export default Users;
