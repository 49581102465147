import React from "react";
import { Button, Input, InputBox } from "../utils/layoutUtils";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { GoChevronDown } from "react-icons/go";
import { Dropdown, Menu, Space } from "antd";
import CustomMenu from "./CustomMenu";

const Table = ({
  children,
  headData = [],
  searchValue,
  onSearchChange,
  noData = [],
  row,
  setRow,
  pagination,
  total_value,
  page = 1,
  setPage,
  total_page,
  onRowChange,
  topleft,
  filter = false,
  items = [],
}) => {
  return (
    <div className=" mt-3">
      {noData?.length == 0 ? (
        <div className="bg-white p-7 flex w-full items-center justify-center">
          <div>No Data Found</div>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between mb-4">
            {pagination ? (
              <div className="w-[100px]">
                <InputBox drop value={row} onChange={onRowChange}>
                  <option value={10}>10</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </InputBox>
              </div>
            ) : (
              <div>{topleft}</div>
            )}
            <div className="flex gap-2 items-center ">
              {filter && <CustomMenu />}
              <input
                className={`w-[300px] block max-w-96 rounded-md border-[1.5px] px-3 py-2 border-gray-200 focus:border-indigo-500 `}
                value={searchValue}
                onChange={onSearchChange}
                placeholder={"Search"}
              />
            </div>
          </div>
          <div className="overflow-x-auto bg-white shadow-lg p-4 rounded-xl">
            <table className="table-auto min-w-full divide-y divide-gray-200 ">
              <thead className="bg-slate-200">
                <tr>
                  {headData.map((item, index) => (
                    <th
                      key={index}
                      className="px-6 py-3 text-[14px] text-[#000] font-medium  capitalize tracking-wider text-center"
                    >
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 ">{children}</tbody>
            </table>
          </div>
          {pagination && (
            <div className="flex gap-10  items-center p-2 bg-slate-200 justify-end">
              <div className="flex gap-2  ">
                <div className="flex font-bold text-md text-gray-500">
                  {(page - 1) * row + 1}
                </div>
                <div className="flex font-bold text-md text-gray-500">-</div>
                <div className="flex font-bold text-md text-gray-500">
                  {row * page}
                </div>
                <div className="flex font-bold text-md text-gray-500">of</div>
                <div className="flex font-bold text-md text-gray-500">
                  {total_value}
                </div>
              </div>
              <div className="flex gap-5">
                <MdArrowBackIosNew
                  size={25}
                  color="gray"
                  className="cursor-pointer"
                  onClick={() => page == 1 || setPage(page - 1)}
                />
                <MdArrowForwardIos
                  size={25}
                  color="gray"
                  className="cursor-pointer"
                  onClick={() => page >= total_page || setPage(page + 1)}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Table;
