import React, { useContext, useEffect, useState } from "react";
import { Heading, Loader } from "../../utils/layoutUtils";
import Table from "../../components/Table";
import myContext from "../../data/myContext";
import { putApi } from "../../utils/apis";
import { toast } from "react-toastify";
import { Image } from "antd";

const QueryList = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const { getQueryList, query_list } = useContext(myContext);

  useEffect(() => {
    getQueryList();
  }, []);

  const onChangeStatus = async (status, id) => {
    setLoading(true);
    const body = JSON.stringify({
      QUERY_ID: id,
      STATUS: status,
    });
    const res = await putApi(`questions-direct/query/status`, body);
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      getQueryList();
    } else {
      toast.error(res.message);
    }
  };

  return (
    <div className="w-full block">
      <div className="flex justify-between items-center">
        <Heading title={"Users Query List"} />
      </div>
      {query_list?.loading && query_list?.list?.length != 0 ? (
        <Loader />
      ) : (
        <Table
          searchValue={search}
          onSearchChange={(v) => {
            setSearch(v.target.value);
          }}
          noData={query_list?.list}
          headData={[
            "S.No.",
            "Full Name",
            "EMAIL_ADDRESS",
            "Description",
            "MOBILE",
            "Document",
            "Status",
          ]}
        >
          {query_list?.list
            ?.filter((item) => {
              return (
                item.FULL_NAME.toLowerCase().includes(search.toLowerCase()) ||
                item.EMAIL_ADDRESS.toLowerCase().includes(
                  search.toLowerCase()
                ) ||
                item.MOBILE.toLowerCase().includes(search.toLowerCase())
              );
            })
            ?.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 text-center">{index + 1}</td>
                <td className="py-2 px-4 text-center">{item.FULL_NAME}</td>
                <td className="py-2 px-4 text-center">{item.EMAIL_ADDRESS}</td>
                <td className="py-2 px-4 text-center">
                  <div className="line-clamp-4">{item.DESCRIPTION}</div>
                </td>
                <td className="py-2 px-4 text-center">{item.MOBILE}</td>
                <td className="py-4 px-4 text-center">
                  <div className="items-center flex justify-center">
                    <Image
                      src={process.env.REACT_APP_IMG_URL + item.FILE_PATH}
                      // width={80}
                      height={60}
                    />
                    {/* <img
                      src={process.env.REACT_APP_IMG_URL + item.FILE_PATH}
                      className="h-[50px] w-[50px] object-contain"
                    /> */}
                  </div>
                </td>
                <td className="p-2 whitespace-nowrap ">
                  <div className="flex gap-2 justify-center">
                    <select
                      className="w-[120px] border-[1px] border-[#655ADF] py-1 px-2 rounded-[5px]"
                      value={item?.STATUS}
                      onChange={(v) =>
                        onChangeStatus(v.target.value, item.QUERY_ID)
                      }
                    >
                      <option value={-1}>Status</option>
                      <option value={1}>Resolved</option>
                      <option value={0}>Pending</option>
                      <option value={2}>Rejected</option>
                    </select>
                  </div>
                </td>
              </tr>
            ))}
        </Table>
      )}
    </div>
  );
};

export default QueryList;
