import React, { useContext, useEffect, useState } from "react";
import UserChart from "./UserChart";
import PolicyChart from "./PolicyChart";
import { Heading } from "../../utils/layoutUtils";
import { DatePicker } from "antd";
import User from "./User";
import { getOneMonthBefore } from "../../utils/utils";
import moment from "moment";
import dayjs from "dayjs";
import myContext from "../../data/myContext";
const { RangePicker } = DatePicker;

const Dashboard = () => {
  const [Selected, setSelected] = useState([]);
  const { getUserReports, user_report, users, provider_all } =
    useContext(myContext);
  const onOk = (value) => {
    console.log("onOk: ", value);
  };
  const today = new Date();
  const oneMonthBefore = getOneMonthBefore(today);
  const dateFormat = "DD-MM-YYYY";
  useEffect(() => {
    setSelected([
      moment(oneMonthBefore).format(dateFormat),
      moment(today).format(dateFormat),
    ]);
    getUserReports(
      moment(oneMonthBefore).format("YYYY/MM/DD"),
      moment(today).format("YYYY/MM/DD")
    );
  }, []);

  return (
    <div className="flex  gap-5 flex-col">
      <div className="flex items-center justify-between">
        <Heading title={"Dashboard"} />
      </div>
      <div className="flex gap-4 ">
        <div className="bg-[#fff] p-3 rounded-md flex flex-col gap-2 flex-1">
          <Heading title={"Users Data"} />
          <div>
            <div>Total Users : {users?.list?.length}</div>
            <div>Active Users : {users?.list?.length}</div>
            <div>Blocked Users : {users?.list?.length}</div>
          </div>
        </div>
        <div className="bg-[#fff] p-3 rounded-md flex flex-col gap-2 flex-1">
          <Heading title={"Providers Data"} />
          <div>
            <div>Total Users : {provider_all?.list?.length}</div>
            <div>Active Users : {provider_all?.list?.length}</div>
            <div>Blocked Users : {provider_all?.list?.length}</div>
          </div>
        </div>
        <div className="bg-[#fff] p-3 rounded-md flex flex-col gap-2 flex-1">
          <Heading title={"Connects Data"} />
          <div>
            <div>Total Connects : {provider_all?.list?.length}</div>
            {/* <div>Active Users : {provider_all?.list?.length}</div>
            <div>Blocked Users : {provider_all?.list?.length}</div> */}
          </div>
        </div>
      </div>
      <div className="flex gap-2 items-center justify-end">
        <div>Date Range</div>
        <RangePicker
          value={[
            dayjs(Selected[0], dateFormat),
            dayjs(Selected[1], dateFormat),
          ]}
          format="DD-MM-YYYY"
          onChange={(value, dateString) => {
            console.log("Selected Time: ", value);
            console.log("Formatted Selected Time: ", dateString);
            setSelected([dateString[0], dateString[1]]);
          }}
          maxDate={dayjs(today, dateFormat)}
          onOk={onOk}
        />
      </div>
      <div className="flex gap-5">
        <User
          user_data={user_report?.data?.user_info}
          user_list={user_report?.data?.user_info?.data}
          provider_data={user_report?.data?.provider_info}
          provider_list={user_report?.data?.provider_info?.data}
        />
        {/* <UserChart />
        <PolicyChart /> */}
      </div>
    </div>
  );
};

export default Dashboard;
