import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Center,
  FileInput,
  Heading,
  InputBox,
  Loader,
  MyModal,
  MyPopup,
  PriorityDrop,
} from "../../utils/layoutUtils";
import Table from "../../components/Table";
import myContext from "../../data/myContext";
import { deleteApi, getApi, postApi, putApi } from "../../utils/apis";
import { toast } from "react-toastify";
import { FiPlus, FiTrash } from "react-icons/fi";
import imageCompression from "browser-image-compression";
import { Image } from "antd";

const DynamicLogo = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detail, setdetail] = useState({});
  const [search, setSearch] = useState("");
  const { getLogoData, logo_data } = useContext(myContext);
  const [compressedFile, setCompressedFile] = useState({});
  const [originalFile, setOriginalFile] = useState({});
  const [originalSize, setOriginalSize] = useState(0);
  const [compressedSize, setCompressedSize] = useState(0);

  useEffect(() => {
    getLogoData();
  }, []);

  const onAdd = async () => {
    if (!detail?.img?.name) {
      toast.info("Logo is required", { toastId: 1 });
      return;
    }
    const body = new FormData();
    body.append("IMAGE", compressedFile);
    setLoading(true);
    const res = await postApi("blogs/logo", body);
    setLoading(false);
    if (res.success) {
      getLogoData();
      toast.success(res.message);
      setOpen(false);
    } else {
      toast.error(res.message);
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    setOriginalFile(URL.createObjectURL(file));
    setOriginalSize(file.size);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      setCompressedFile(compressedFile);
      // setCompressedFile(URL.createObjectURL(compressedFile));
      setCompressedSize(compressedFile.size);
    } catch (error) {
      console.error("Error compressing image:", error);
    }
  };
  return (
    <div className="w-full block">
      <div className="flex justify-between items-center">
        <Heading title={"Users Query List"} />
        <Button
          title={"Add"}
          icon={<FiPlus size={20} />}
          onClick={() => {
            setdetail({});
            setOpen(true);
          }}
        />
      </div>

      {logo_data?.loading && logo_data?.list?.length != 0 ? (
        <Loader />
      ) : (
        <Table
          searchValue={search}
          onSearchChange={(v) => {
            setSearch(v.target.value);
          }}
          noData={logo_data?.list}
          headData={["S.No.", "TITLE", "LOGO IMAGE"]}
        >
          {logo_data?.list
            // ?.filter((item) => {
            //   return (
            //     item.FULL_NAME.toLowerCase().includes(search.toLowerCase()) ||
            //     item.EMAIL_ADDRESS.toLowerCase().includes(
            //       search.toLowerCase()
            //     ) ||
            //     item.MOBILE.toLowerCase().includes(search.toLowerCase())
            //   );
            // })
            ?.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 text-center">{index + 1}</td>
                <td className="py-2 px-4 text-center">{item.TITLE}</td>

                <td className="py-4 px-4 text-center">
                  <div className="items-center flex justify-center">
                    <Image
                      src={process.env.REACT_APP_IMG_URL + item.IMAGE_URL}
                      height={150}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </Table>
      )}
      <MyModal isOpen={open} setIsOpen={setOpen} title={"Add Logo"}>
        <div className="p-5 w-full">
          <div className="flex w-full gap-4">
            <FileInput
              doctype={".png,.jpg,.jpeg"}
              label={"Logo Image"}
              fileName={detail.img?.name}
              onChange={(v) => {
                handleImageUpload(v);
                setdetail({
                  ...detail,
                  img: v.target.files[0],
                  imgMess: "",
                });
              }}
              error={detail.imgMess}
            />
          </div>

          <Center className={"mt-8"}>
            <Button title={"Submit"} onClick={onAdd} loading={loading} />
          </Center>
        </div>
      </MyModal>
    </div>
  );
};

export default DynamicLogo;
