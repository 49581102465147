import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Heading,
  Loader,
  MyModal,
  ViewData,
} from "../../utils/layoutUtils";
import Table from "../../components/Table";
import myContext from "../../data/myContext";
import { exportPDF, handleExcel } from "../../utils/utils";
import CustomMenu from "../../components/CustomMenu";
import { Image, Switch, Tooltip } from "antd";
import { putApi } from "../../utils/apis";
import { toast } from "react-toastify";

const ProviderList = () => {
  const [open1, setOpen1] = useState(false);
  const [detail, setdetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const { getProvidersList, provider_all, category } = useContext(myContext);

  useEffect(() => {
    getProvidersList();
  }, []);

  const table_data = provider_all?.list?.map((elt, i) => [
    i + 1,
    elt.PROFILE?.FULL_NAME,
    elt.PROFILE?.EMAIL_ADDRESS,
    elt.PROFILE?.PHONE_NUMBER,
    elt.ADDRESS,
    elt.SHOP_ADDR,
    elt.EXPERIENCE,
  ]);

  const export_pdf = () => {
    exportPDF({
      table_data: table_data,
      table_headers: [
        "S.No.",
        "Full Name",
        "Email Address",
        "Phone Number",
        "Address",
        "Shop Address",
        "Experience",
      ],
      fileName: "provider-list",
      tableName: "MyCall Book Provider List",
    });
  };

  const change_status = async (status, id) => {
    setLoading(true);
    const raw = JSON.stringify({
      USER_ID: id,
      STATUS: status,
    });
    const res = await putApi("user/block", raw);
    setLoading(false);
    if (res.success) {
      getProvidersList();
      toast.success(res.message, { toastId: 1 });
    } else {
      toast.error(res.message, { toastId: 1 });
    }
  };
  console.log(search);
  return (
    <div className="w-full block">
      <div className="flex justify-between items-center">
        <Heading title={"Service Provider List"} />
      </div>

      {provider_all?.loading && provider_all?.list?.length != 0 ? (
        <Loader />
      ) : (
        <Table
          filter
          // items={items}
          // pagination={true}
          topleft={
            <div className="flex gap-2 w-full justify-between ">
              <Button title={"PDF Export"} onClick={() => export_pdf()} />
              <Button
                title={"XLSX Export"}
                onClick={() => handleExcel(provider_all?.list, "provider-list")}
              />
            </div>
          }
          searchValue={search}
          onSearchChange={(v) => {
            setSearch(v.target.value);
          }}
          noData={provider_all?.list}
          headData={[
            "S.No.",
            "Block",
            "FULL_NAME",
            "EMAIL_ADDRESS",
            "PHONE_NUMBER",
            "Business Name",
            "Business Address",
            "Business Email",
            "Business Contact No.",
            "Pincode",
            "Id Proof",
            "Address Proof",
          ]}
        >
          {provider_all?.list
            ?.filter((item) => {
              return (
                item.PROFILE?.FULL_NAME?.toLowerCase().includes(
                  search.toLowerCase()
                ) ||
                item.PROFILE?.EMAIL_ADDRESS?.toLowerCase().includes(
                  search.toLowerCase()
                ) ||
                item.PROFILE?.PHONE_NUMBER?.toLowerCase().includes(
                  search.toLowerCase()
                ) ||
                item.BUSINESS_NAME.toLowerCase().includes(
                  search.toLowerCase()
                ) ||
                item.BUSINESS_EMAIL.toLowerCase().includes(
                  search.toLowerCase()
                ) ||
                item.BUSINESS_NUMBER.toString().includes(search)
              );
            })
            ?.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 text-center">{index + 1}</td>
                <td className="py-2 px-4 text-center">
                  <Switch
                    value={item?.PROFILE?.BLOCKED}
                    onChange={(e) => change_status(e, item.USER_ID)}
                  />
                </td>
                <td
                  className="py-2 px-4 text-center font-sans cursor-pointer text-indigo-500"
                  onClick={() => {
                    setdetail(item);
                    setOpen1(true);
                  }}
                >
                  {item.PROFILE?.FULL_NAME}
                </td>
                <td className="py-2 px-4 text-center font-sans">
                  {item.PROFILE?.EMAIL_ADDRESS}
                </td>
                <td className="py-2 px-4 text-center font-sans">
                  {item.PROFILE?.PHONE_NUMBER}
                </td>
                <td className="py-2 px-4 text-center font-sans">
                  <div className="line-clamp-4">{item.BUSINESS_NAME}</div>
                </td>
                <td className="py-2 px-4 text-center font-sans">
                  <Tooltip title={item.BUSINESS_ADDRESS}>
                    <div className="w-[250px] line-clamp-4 font-sans">
                      {item.BUSINESS_ADDRESS}
                    </div>
                  </Tooltip>
                </td>
                <td className="py-2 px-4 text-center font-sans">
                  <div className="line-clamp-4">{item.BUSINESS_EMAIL}</div>
                </td>
                <td className="py-2 px-4 text-center font-sans">
                  <div className="line-clamp-4">{item.BUSINESS_NUMBER}</div>
                </td>
                <td className="py-2 px-4 text-center font-sans">
                  <div className="line-clamp-4">{item.PINCODE}</div>
                </td>
                <td className="py-4 px-4 text-center">
                  <div className="items-center flex justify-center">
                    <Image
                      src={process.env.REACT_APP_IMG_URL + item.ID_CARD}
                      // width={80}
                      height={80}
                    />
                  </div>
                </td>
                <td className="py-4 px-4 text-center">
                  <div className="items-center flex justify-center">
                    <Image
                      src={process.env.REACT_APP_IMG_URL + item.ADDR_PROOF}
                      // width={80}
                      height={80}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </Table>
      )}

      <MyModal
        isOpen={open1}
        setIsOpen={setOpen1}
        title={"Service Provider Details"}
      >
        <div className="p-5">
          <p className="mb-2 font-sans font-medium text-lg text-[#44195E]">
            Personal Detail
          </p>
          <ViewData title={"Full Name"} value={detail?.PROFILE?.FULL_NAME} />
          <ViewData
            title={"Email Address"}
            value={detail?.PROFILE?.EMAIL_ADDRESS}
          />
          <ViewData
            title={"Contact Number"}
            value={detail?.PROFILE?.PHONE_NUMBER}
          />
          <p className="mb-2 font-sans font-medium text-lg text-[#44195E]">
            Business Detail
          </p>
          <ViewData title={"Business Name"} value={detail?.BUSINESS_NAME} />
          <ViewData
            title={"Business Email Address"}
            value={detail?.BUSINESS_EMAIL}
          />
          <ViewData title={"Contact Number"} value={detail?.BUSINESS_NUMBER} />
          <ViewData title={"DESCRIPTION"} value={detail?.DESCRIPTION} />
          <ViewData
            title={"Business Address"}
            value={detail?.BUSINESS_ADDRESS}
          />
          <ViewData title={"Experience"} value={detail?.EXPERIENCE} />
          {detail?.WEB_URL && (
            <ViewData title={"Website Url"} value={detail?.WEB_URL} />
          )}
          <ViewData title={"Pincode"} value={detail?.PINCODE} />

          {detail?.CATEGORY_INFO?.length > 0 && (
            <ViewData
              title={"Category"}
              value={detail?.CATEGORY_INFO[0]?.C_NAME}
            />
          )}
          {detail?.SERVICE_INFO?.length > 0 &&
            detail?.SERVICE_INFO[0]?.SUB_SERVICES_DATA?.length > 0 && (
              <ViewData
                title={"Sub Categories"}
                value={detail?.SERVICE_INFO[0]?.SUB_SERVICES_DATA?.map(
                  (v) => v.S_NAME
                )?.toString()}
              />
            )}
          <ViewData title={"ID Proof"} img={detail?.ID_CARD} />
          <ViewData title={"Address Proof"} img={detail?.ADDR_PROOF} />
          <ViewData title={"Work Gallery"} list={detail?.WORK_GALLERY} />
        </div>
      </MyModal>
    </div>
  );
};

export default ProviderList;
