import { GoChevronDown } from "react-icons/go";
import React, { useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { Oval } from "react-loader-spinner";
import { IoIosArrowDown } from "react-icons/io";
import { Image } from "antd";

const Card = ({ children, CardStyle }) => {
  return (
    <div
      className={`bg-[#fff] rounded-md border-[2px] border-[#EBECF3] p-10 ${CardStyle}`}
    >
      {children}
    </div>
  );
};
const CardHeading = ({ children, CardStyle, heading, MainStyle }) => {
  return (
    <div>
      <div
        className={`font-sans font-bold text-[16px] text-[#5F5F5F] ${MainStyle}`}
      >
        {heading}
      </div>
      <div
        className={`bg-[#fff] flex flex-col rounded-lg border-[2px] border-[#D9D9D9] p-4 mt-2 justify-center ${CardStyle}`}
      >
        {children}
      </div>
    </div>
  );
};

const Pagination = ({ c_tab = 0, data = [], line = 0 }) => {
  return data.map((item, index) => (
    <div className="flex flex-col ">
      <div className="flex gap-2 items-center">
        {index <= c_tab ? (
          <div className="w-[30px] flex justify-center ">
            <div className="h-[27px] w-[27px] bg-[#fff] border-[2px] border-[#655ADF] rounded-full items-center justify-center flex">
              <div className="h-[20px] w-[20px] bg-[#655ADF] rounded-full items-center justify-center flex">
                <div className="font-plus-jakarta-sans text-[13px] font-semibold text-[#fff]">
                  {index + 1}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-[30px] flex justify-center ">
            <div className="h-[25px] w-[25px] bg-[#B5B5B5] rounded-full items-center justify-center flex">
              <div className="font-plus-jakarta-sans text-[13px] font-semibold text-[#fff]">
                {index + 1}
              </div>
            </div>
          </div>
        )}
        <div
          className={`font-sans text-[12px]  ${
            index <= c_tab
              ? "text-[#655ADF] font-extrabold"
              : "text-[#B5B5B5] font-light"
          }`}
        >
          {item}
        </div>
      </div>
      {index + 1 == data.length || (
        <div className="w-[30px] flex justify-center ">
          <div
            className={`h-[100px] w-[2px] ${
              index < c_tab ? "bg-[#655ADF]" : "bg-[#B5B5B5]"
            } flex `}
          >
            {index + 1 <= line && (
              <div className={`h-[50px] w-full bg-[#655ADF]`} />
            )}
          </div>
        </div>
      )}
    </div>
  ));
};

const BoldText = ({ children, color, fontSize }) => {
  return (
    <div
      className="font-sans font-bold text-[30px] text-[#000]"
      style={{ color: color, fontSize: fontSize }}
    >
      {children}
    </div>
  );
};

const InputBox = ({
  leftIcon,
  rightIcon,
  label,
  placeholder,
  seconday = true,
  drop = false,
  children,
  value,
  onChange,
  error,
  area = false,
  disabled,
  type,
}) => {
  return (
    <div className=" flex-1 block">
      {label && (
        <div className="font-sans font-normal text-[16px] text-black mb-2 mt-2">
          {label}
        </div>
      )}
      <div
        className={`relative rounded-lg overflow-hidden flex-1 flex   ${
          seconday
            ? "border-[#EBECF3] border-[1.5px] bg-[#fff]"
            : "bg-[#F5F5FB]"
        }`}
      >
        {leftIcon && (
          <div className=" inset-y-0 left-0 flex items-center pl-3">
            {leftIcon}
          </div>
        )}
        {drop ? (
          <>
            <select
              value={value}
              onChange={onChange}
              className=" text-[#454545] flex-1 block appearance-none font-sans font-light text-[13px]  px-3 py-2 pr-10 rounded  focus:outline-none "
            >
              {children}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <GoChevronDown color="#454545" size={20} />
            </div>
          </>
        ) : area ? (
          <textarea
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={`mt-1 block w-full font-sans font-light text-[13px] text-[#000] placeholder-[#454545]  resize-y min-h-[100px] rounded-md  px-3 py-1 focus:outline-none  `}
          />
        ) : (
          <input
            type={type || "text"}
            className="block font-sans font-light text-[13px] text-[#000] placeholder-[#454545] flex-1 px-3 py-2 focus:outline-none bg-transparent"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        )}
        {rightIcon && (
          <div className=" inset-y-0 right-0 flex items-center pr-3">
            {rightIcon}
          </div>
        )}
      </div>
      {error && (
        <div className="font-sans font-normal text-[16px] text-[#f00] mt-1">
          {error}
        </div>
      )}
    </div>
  );
};

function FileInput({ label, error, doctype, onChange, multiple, fileName }) {
  const inputOpenFileRef = useRef();
  const showOpenFileDlg = () => {
    if (inputOpenFileRef.current) {
      // inputOpenFileRef.current.click();
    }
  };

  return (
    <div className="mb-4 flex-1">
      <label htmlFor="email" className="block text-gray-700 mt-2">
        {label}
      </label>
      <div
        className={`mt-1.5 flex w-full items-center resize-y rounded-md border-[1.5px] border-gray-200 focus:border-indigo-500 ${
          error ? "border-red-500" : ""
        }`}
        onClick={showOpenFileDlg}
      >
        <label
          htmlFor={`fileInput${label}`}
          className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white py-1.5 px-4 rounded "
        >
          Browse
        </label>
        <label
          className="px-2 flex-1 line-clamp-1"
          htmlFor={`fileInput${label}`}
        >
          {fileName ? fileName : "Choose File"}
        </label>
        <input
          // ref={inputOpenFileRef}
          onChange={onChange}
          multiple={multiple}
          accept={doctype || "image/png, image/jpg, image/jpeg ,.pdf"}
          id={`fileInput${label}`}
          type="file"
          className="hidden"
        />
      </div>
    </div>
  );
}

const Input = ({ label, value, onChange, error, placeholder, area, type }) => {
  return (
    <div className="mb-4 flex-1">
      <label htmlFor="email" className="block text-gray-700">
        {label}
      </label>
      {area ? (
        <textarea
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={`mt-1 block w-full resize-y min-h-[100px] rounded-md border-[1.5px] px-3 py-1 border-gray-200 focus:border-indigo-500 ${
            error ? "border-red-500" : ""
          }`}
        />
      ) : (
        <input
          className={`mt-1 block w-full rounded-md border-[1.5px] px-3 py-1 border-gray-200 focus:border-indigo-500 ${
            error ? "border-red-500" : ""
          }`}
          value={value}
          type={type}
          onChange={onChange}
          placeholder={placeholder}
        />
      )}
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};
function Loader1() {
  return (
    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
  );
}
const Button = ({ onClick, title, icon, loading }) => {
  return (
    <button
      disabled={loading}
      className={`flex items-center gap-2 h-[30px] min-w-24 justify-center bg-indigo-500 text-white py-1 px-4 font-normal rounded-md hover:bg-indigo-600 ${
        loading ? "opacity-50 cursor-not-allowed" : "cursor-pointer "
      }`}
      onClick={onClick}
    >
      {loading ? (
        <Oval
          visible={true}
          height="20"
          width="20"
          color="#fff"
          strokeWidth={5}
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      ) : (
        <>
          {icon}
          {title}
        </>
      )}
    </button>
  );
};

const Heading = ({ title }) => {
  return <div className="font-medium text-slate-900 text-xl">{title}</div>;
};
function TabBar({ activeTab = 1, setActiveTab, TabData = [] }) {
  // const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="flex mt-2">
      <div className=" w-full flex items-center self-start bg-[#c8cee2] gap-1 p-1 overflow-x-scroll">
        {TabData.map((item, index) => (
          <button
            key={index}
            className={` flex-1 whitespace-nowrap overflow-ellipsis flex px-5 py-2 justify-center ${
              activeTab === index + 1
                ? "text-[#1c4ed8] bg-white"
                : "text-white  hover:bg-[#ffffff33]"
            }`}
            onClick={() => handleTabClick(index + 1)}
          >
            {item?.name}
          </button>
        ))}
      </div>
    </div>
  );
}

const Center = ({ children, className }) => {
  return (
    <div
      className={`w-full min-w-full flex flex-1 items-center justify-center ${className}`}
    >
      {children}
    </div>
  );
};

function MyPopup({
  isOpen,
  setIsOpen,
  title,
  children,
  message,
  bname,
  onClick,
  loading,
  primary,
}) {
  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      setIsOpen(false);
    }
  };
  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none modal-overlay"
          onClick={handleOutsideClick}
        >
          <div className="fixed inset-0 bg-black opacity-50  modal-overlay"></div>
          <div className="relative w-auto max-w-md mx-auto my-6">
            <div className="relative flex flex-col bg-white border-2 border-gray-200 shadow-md rounded-lg xl:min-w-96 md:min-w-full">
              {/* Modal header */}
              <div className="px-6 py-4 border-b border-gray-200">
                <h3 className="text-lg font-medium text-gray-900">{title}</h3>
              </div>
              {/* Modal body */}
              {children ? (
                children
              ) : (
                <div className="px-6 py-4">
                  <p className="text-md text-gray-700">{message}</p>
                </div>
              )}
              {/* Modal footer */}
              <div className="px-6 py-4 flex justify-end border-t border-gray-200">
                <button
                  className="text-sm text-gray-500 hover:text-gray-700 mr-3 bg-slate-400 py-1 px-3 rounded-md"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
                <button
                  disabled={loading}
                  className={`text-sm justify-center  flex text-white min-w-16  py-1 px-3 rounded-md 
                  ${
                    loading
                      ? "opacity-50 cursor-not-allowed"
                      : "cursor-pointer "
                  }${
                    primary
                      ? "bg-indigo-500 hover:bg-indigo-600"
                      : "bg-red-700 hover:bg-red-800"
                  }`}
                  onClick={onClick}
                >
                  {loading ? (
                    <Oval
                      visible={true}
                      height="18"
                      width="18"
                      color="#fff"
                      strokeWidth={5}
                      ariaLabel="oval-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    bname
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
function MyModal({ isOpen, setIsOpen, title, children }) {
  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      setIsOpen(false);
    }
  };
  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 z-50  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none modal-overlay"
          onClick={handleOutsideClick}
        >
          <div className="fixed inset-0 bg-black opacity-50 modal-overlay"></div>
          <div className="relative w-full md:w-[60%] mx-auto my-6 max-h-[90%] overflow-y-scroll">
            <div className="relative flex flex-col bg-white border-2 border-gray-200 shadow-md rounded-lg  md:min-w-full">
              {/* Modal header */}
              <div className="px-4 py-2 border-b border-gray-200 flex items-center justify-between">
                <h3 className="text-lg font-medium text-gray-900">{title}</h3>
                <IoMdClose
                  color="#000"
                  size={20}
                  onClick={() => setIsOpen(false)}
                  className=""
                />
              </div>

              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const Loader = () => {
  return (
    <div className="flex items-center justify-center w-full  bg-white py-6">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-700"></div>
    </div>
  );
};

const ViewData = ({ title, value, img, url, list = [] }) => {
  return (
    <div className="mb-5 flex-1 block">
      <label className="block text-gray-700 text-sm">{title}</label>
      {list.length > 0 && (
        <div className="flex items-center gap-3 flex-wrap">
          {list.map((v, i) => (
            <Image
              key={i}
              height={150}
              src={process.env.REACT_APP_IMG_URL + v.path}
            />
            // <img
            //   key={i}
            //   src={process.env.REACT_APP_IMG_URL + v.path}
            //   className="h-[150px] w-[150px] object-contain"
            // />
          ))}
        </div>
      )}
      {img ? (
        <Image height={200} src={process.env.REACT_APP_IMG_URL + img} />
      ) : (
        // <img
        //   src={process.env.REACT_APP_IMG_URL + img}
        //   className="h-[150px] w-[150px] object-contain"
        // />
        <div className="text-black text-md mt-1 py-2 px-2 bg-[#91B4E426]">
          {value}
        </div>
      )}
    </div>
  );
};

const Spacer = ({ height }) => {
  return <div className="w-full" style={{ height: height || 10 }}></div>;
};

const Tooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="relative inline-block">
      <div
        className="inline-block"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      {showTooltip && (
        <div className="absolute z-10 -mt-16 p-2 bg-gray-800 text-white rounded-md shadow-md whitespace-nowrap">
          {text}
        </div>
      )}
    </div>
  );
};

export const PriorityDrop = ({ value, list, handleSelectChange }) => {
  return (
    <div className="relative w-[100px]">
      <select
        value={value}
        onChange={handleSelectChange}
        className="block appearance-none w-full bg-white border border-gray-300 px-4 py-2 pr-8 rounded-[5px] shadow leading-tight focus:outline-none focus:shadow-outline"
      >
        {list}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <IoIosArrowDown size={18} color="#000" />
      </div>
    </div>
  );
};

const Toggle = ({ title, checked = false, onChange }) => {
  return (
    <div className="flex gap-4 items-center">
      {title}
      <label
        htmlFor={`AcceptConditions +${title}`}
        className="relative inline-block h-6 w-12 cursor-pointer rounded-full bg-gray-300 transition [-webkit-tap-highlight-color:_transparent] has-[:checked]:bg-green-500"
      >
        <input
          type="checkbox"
          id={`AcceptConditions +${title}`}
          className="peer sr-only"
          checked={checked}
          // onChange={(e) => setChecked(e.target.checked)}
          onChange={onChange}
        />

        <span className="absolute inset-y-0 start-0 m-1 size-4 rounded-full bg-white transition-all peer-checked:start-6"></span>
      </label>
    </div>
  );
};

export {
  Card,
  Pagination,
  BoldText,
  CardHeading,
  InputBox,
  Button,
  Input,
  Heading,
  Center,
  MyPopup,
  Loader,
  MyModal,
  ViewData,
  Spacer,
  Tooltip,
  FileInput,
  TabBar,
  Toggle,
};
